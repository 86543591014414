.login {
    height: calc(var(--vh, 1vh) * 100 - var(--header-height));

    @media (--tablet) {
        max-width: var(--narrow-page-width);

        margin: 0 auto;
    }

    .big,
    .smaller {
        margin-bottom: 2rem;
    }

    input {
        margin-top: 4.4rem;
    }

    .error {
        border-bottom: 1px solid var(--red);
        color: var(--red);
        margin-bottom: 1.2rem;
    }

    h3 {
        margin-bottom: 1.2rem;
    }

    .smaller {
        &--error {
            color: var(--red);
        }
    }

    span {
        text-decoration: underline;
        cursor: pointer;
    }

    &__link-sent {
        span {
            color: var(--orange);
        }

        p:last-child {
            @media (--tablet) {
                margin-bottom: 6.4rem;
            }
        }
    }

    &__cta {
        @media (--tablet) {
            display: flex;
            justify-content: space-between;

            margin-top: 2rem;

            p {
                max-width: 27.6rem;
            }

            button {
                height: 5.6rem;
            }
        }
    }
    
    .action-flags {
        display: flex;
        
        p {
            width: 70%;
            @media (--tablet) {
                width: 90%;
            }
        }

        .flags-box {
            width: 30%;
            .flag {
                width: 3rem;
                height: 3rem;
            }
            .selected {
                border: 2px solid black;
            }

            @media (--tablet) {
                width: 20%;
            }
        }
    }
}
