.qna-container {

  @media screen and (min-width: 800px) {
    max-width: var(--wide-page-width);
    margin: 0 auto;
  }

  .qna-text-container {
    margin: 4rem auto;

    @media screen and (min-width: 800px) {
      margin: 6rem auto;
    }
  }

  h1 {
    font-size: 2rem;
    font-weight: 600;
    color: black;
    line-height: normal;
    margin-bottom: 2rem;
  }
}