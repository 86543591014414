.section-with-badge {
    position: relative;

    &__title {
        margin-bottom: 2rem;
        position: relative;

        @media (--tablet) {
            margin-bottom: 4rem;
        }
    }

    &__badge {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0.3rem;
        right: 0;
        color: var(--orange);

        font-style: normal;
        font-weight: var(--font-weight-regular);
        font-size: 2rem;
        line-height: 2.7rem;
        /* identical to box height, or 135% */

        text-align: right;
        text-align: center;
        font-size: 2rem;
        line-height: 2.7rem;

        @media (--tablet) {
            display: inline-flex;
            position: relative;
            top: 0;
            margin-left: 2.4rem;
            font-size: 2.8rem;
            line-height: 3.4rem;
        }
    }

    &__divider {
        @media (--tablet) {
            margin: 4.8rem auto;
        }
    }

    &__buttons {
        position: absolute;
        right: 0;
        top: 0;
        display: none;
        /* z-index: 1; */

        @media (--tablet) {
            display: block;
        }
    }

    &__button {
        appearance: none;
        border: none;
        background: transparent;
        padding: 0 0.8rem;

        right: 0;
        top: 0;

        &:disabled {
            opacity: 0.5;
        }

        &--left img {
            transform: rotate(180deg);
        }
    }

    &.at-end .horizontal-scroll__wrapper::after {
        opacity: 0;
    }
}
