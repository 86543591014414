.horizontal-scroll {
    --gradient-width: 10rem;

    position: relative;
    display: flex;
    flex-flow: row nowrap;

    overflow-x: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    &.no-scrollbar {
        scrollbar-width: none;
    }

    &.no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    &--full-width {
        margin-left: calc(var(--gutter) * -1);
        margin-right: calc(var(--gutter) * -1);
    }

    @media (--tablet) {
        &::before {
            margin-left: calc(50% - var(--wide-page-width) / 2 + var(--gutter)) !important;
        }

        &::after {
            margin-left: calc(50% - var(--wide-page-width) / 2 + var(--gutter)) !important;
        }

        &__wrapper {
            /* width: 100%; */
            position: relative;
        }

        &__wrapper::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: calc(50% - var(--page-width) / 2 + var(--gradient-width)) !important;
            height: 100%;
            transition: opacity 0.3s;
            pointer-events: none;
            background-image: linear-gradient(to right,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 0.8) var(--gradient-width),
                    rgba(255, 255, 255, 0.95) 100%);
        }
    }
}

/* vertical list layout for mobile */
.horizontal-scroll {
    &__wrapper {
        &--vertical {
            margin: 0;

            @media (--tablet) {
                margin-left: calc(var(--gutter) * -1);
                margin-right: calc(var(--gutter) * -1);
            }
        }
    }

    &--vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow-x: auto;

        .horizontal-scroll-item {
            margin-right: 0;
            margin-bottom: 2.8rem;
        }

        @media (--tablet) {
            flex-direction: row;
            overflow-x: scroll;
            justify-content: flex-start;

            .horizontal-scroll-item {
                margin-right: var(--gutter);
                margin-bottom: 0;
            }
        }
    }
}

.horizontal-scroll::before,
.horizontal-scroll::after {
    content: "";
    width: var(--gutter);
    min-width: var(--gutter);

    @media (--tablet) {
        width: 1px;
        min-width: 1px;
    }
}

.horizontal-scroll>li,
.topic-tile-container,
.horizontal-scroll-item {
    scroll-snap-align: center;
    margin-right: var(--gutter);
    flex: 0 0 30rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &:last-child {
        margin-right: 0;
    }

    @media (--tablet) {
        flex: 0 0 28rem;
    }
}