.update-profile {
    height: calc(var(--vh, 1vh) * 100 - var(--header-height));

    @media (--tablet) {
        max-width: var(--narrow-page-width);
        margin: 0 auto;
    }

    .big {
        margin-bottom: 4.4rem;
    }

    input {
        display: block;
        margin-bottom: 2rem;
        width: 100%;
        height: 4.8rem;
        color: black;
    }

    button {
        margin-top: 2rem;

        @media (--tablet) {
            margin-top: 0;
        }
    }

    &__cta {
        @media (--tablet) {
            display: flex;
            justify-content: space-between;

            margin-top: 2rem;

            p {
                max-width: 27.6rem;
            }

            button {
                height: 5.6rem;
            }
        }
    }

    .custom-select {
        width: 100%;
        padding: 2rem 0 1.4rem 0;
        border: none;
        border-bottom: 1px solid var(--mid-gray);
        font-size: 1.7rem;
        background: white;
    }
    .custom-select:focus {
        outline: none;
    }
    
    &--modal {
        height: auto;

        .page-wrapper {
            padding-top: 0;
        }

        .update-profile__cta {
            flex-direction: row;

            .button-secondary {
                margin-right: 1.5rem;
            }

            @media (--tablet) {
                display: flex;
                justify-content: space-between;

                button {
                    width: 48%;
                }
            }
        }
    }

    span {
        text-decoration: underline;
        cursor: pointer;
    }
}
