.loading-screen {
    background-color: var(--orange);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;

    &__logo {
        width: 15.4rem;
        height: 8.3rem;

        svg {
            height: 100%;
            width: 100%;
        }
    }

    h5 {
        color: var(--white);
        margin-top: 1.9rem;
    }
}
