.list {
  min-height: var(--survey-min-height);
  display: flex;
  justify-content: center;
  align-items: center;

  &__header {
    margin-bottom: 3.5rem;

    &_title {
      font-size: 2.6rem;
      line-height: inherit;
      margin-bottom: 1rem;
    }

    &_subtitle {
      font-size: 1.4rem;
      line-height: inherit;
    }
  }
}

.checklist-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  .checklist-outer-square {
    width: 1.8rem;
    height: 1.8rem;
    border: 2px solid var(--orange);
    border-radius: 4px;
    cursor: pointer;
    margin-right: 0.7rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .checklist-inner-square {
      width: 1.2rem;
      height: 1.2rem;
      background-color: var(--orange);
      border-radius: 4px;
    }
  }

  span {
    font-size: 1.8rem;
    line-height: 1;
  }
}