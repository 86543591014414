.SI_SecondPage-container {
  @media screen and (min-width: 800px) {
    max-width: var(--wide-page-width);
    margin: 0 auto;
  }
  .page-wrapper {

    .right-answers-container {
      margin: 4rem auto;

      @media screen and (min-width: 800px) {
        margin: 6rem auto;
      }
      p {
        font-size: inherit;
        color: black;
        span {
          color: #31a368;
          font-weight: 600;
        }
      }
      h1 {
        font-size: 2rem;
        font-weight: 600;
        color: black;
        line-height: normal;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
    }
  }
}

.answer-description-container {
  box-shadow: 0px 0px 10px -4px;
  border-radius: 7px;
  width: 100%;
  margin-bottom: 3rem;
  padding: 2rem 2rem;

  @media screen and (min-width: 800px) {
    padding: 5rem 3rem;
  }

  .right-answer-container {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    div {
      display: flex;
    }
    
    h1 {
      font-size: 1.7rem;
      color: #31a368;
      line-height: normal;
    }

    svg {
      font-size: 3rem;
      color: #31a368;
      margin-right: 1rem;
    }
  }

  .wrong-answer-container {
    display: flex;
    align-items: center;

    div {
      display: flex;
    }
    
    h1 {
      font-size: 1.7rem;
      color: #c92424;
      line-height: normal;
    }

    svg {
      font-size: 3rem;
      color: #c92424;
      margin-right: 1rem;
    }
  }

  p {
    margin-top: 2rem;
    line-height: normal;
    font-weight: 400;
  }

  .references-container {
    h1 {
      font-size: 1.7rem;
      font-weight: 600;
      line-height: 3rem;
      margin-top: 1rem;
    }

    ul {
      list-style-type: disc;
      padding-left: 1rem;
      li {
        font-size: 1.2rem;
      }
    }
  }
}