.completed-science-btns-container {
  width: 100%;
  text-align: center;
  
  button {
    margin: .5rem 0;

    @media screen and (min-width: 800px) {
      margin: 0 .5rem;
    }
  }
}