.survey-component {
    .page-wrapper {
        padding-top: 0;
        padding-bottom: 0;
    }

    &__progress {
        padding: 1.6rem 0 1.2rem 0;
        height: var(--survey-header-height);
        border-bottom: 2px solid var(--light-gray);
        background-color: var(--white);
        position: sticky;
        top: 0;
        z-index: 99;

        @media (--tablet) {
            max-width: var(--page-width);
            margin: 0 auto;
        }

        &__heading {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h4 {
                color: var(--petrol);
                flex: 1;
                max-width: 25rem;
                margin-bottom: 0.5rem;
                height: 2.7rem;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                @media (--tablet) {
                    max-width: 50rem;
                    height: auto;
                }
            }

            svg {
                cursor: pointer;
            }
        }

        .progress-bar__value {
            margin-bottom: 0;
        }
    }

    &__survey {
        /* survey component */

        &__thanks-page {
            position: absolute;
            top: 0;
            left: 2rem;
            right: 2rem;
            width: calc(100% - 4rem);
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &__background {
                width: 100%;
                height: 100%;
                background-image: url("../../assets/images/rippl-shape.svg");
                background-position: center;
                background-repeat: no-repeat;
                position: fixed;
                left: 0;
                top: 0;
                z-index: -1;
            }

            h1 {
                font-size: 30px;
                text-align: center;
                z-index: 1;
                color: var(--black);
                margin-bottom: 4.4rem;
                max-width: var(--narrow-page-width);
            }

            @media (--tablet) {
                h1 {
                    font-size: var(--h1);
                }
            }

            @media screen and (max-width: 600px) and (max-height: 700px) {
                h1 {
                    font-size: 20px;
                    line-height: 1.6;
                    margin-bottom: 20px;
                    padding-top: 25px;
                    padding-bottom: 25px;
                }
            }   
        }

        min-height: var(--survey-min-height);

        strong {
            font-weight: normal;
            color: var(--orange);
        }

        .sv_main button {
            min-width: auto;
            line-height: 0;
            &:hover {
                background: none;
            }
        }

        /* style for surveyjs default elements */
        .sv_main .sv_body,
        .sv_main .sv_p_root > .sv_row {
            border: none;
        }

        .sv_body {
            font-family: var(--font-family);
            font-weight: var(--font-weight-regular);
            font-size: var(--p-larger);
            line-height: 2.7rem;
            color: var(--dark-gray);
        }
        .sv_main {
            padding-bottom: 12rem;
            background-color: var(--white);

            .sv_custom_header {
                background-color: transparent;
            }

            @media (--tablet) {
                padding-bottom: 12rem;
                width: 60rem;
                margin: 0 auto;
            }

            @media (--big-screen) {
                width: 70rem;
            }
        }

        .sv_main,
        .sv_body .sv_p_root > .sv_row,
        .sv_main .sv_body,
        .sv_main .sv_p_root > .sv_row:nth-child(odd),
        .sv_main .sv_p_root > .sv_row:nth-child(even) {
            background-color: transparent;
        }

        .sv_page_title {
            margin-bottom: 2.2rem;
        }
        .sv_main .sv_container .sv_completed_page {
            display: none;
        }
        .sv_main .sv_container .sv_body,
        .sv_main .sv_container .sv_body .sv_p_root .sv_q {
            padding: 0;
        }

        .sv_main .sv-title-actions__title {
            flex-wrap: wrap;
            max-width: 100%;
            min-width: 50%;
        }
        
        .sv_main .sv_container .sv_body .sv_p_root fieldset.sv_qcbc {
            margin-top: 1rem !important;

            sv-q-col-1 {
                margin: 0 !important;
            }
            div {
                margin-bottom: .5rem;
                label {
                    cursor: pointer;
                    font-size: 1.7rem;
                    .circle {
                        &:before {
                            border-color: var(--orange);
                            border-radius: 50%;
                            border-style: solid;
                            border-width: 2px;
                            height: 2.5rem;
                            width: 2.5rem;
                        }    
                    }
                }
            }
        }

        .sv_main .sv_container .sv_body .sv_p_root.sv_p_swipe > .sv_row {
            margin-bottom: 0;
        }

        /* style default surveyjs header in case class isn't applied on first load */
        .sv_main .sv_container .sv_header {
            padding: 0;
            padding-bottom: 2.8rem;
        }

        .sv_main .sv_container .sv_header h3 span {
            font-size: var(--h2);
            font-weight: var(--font-weight-regular);
            line-height: 3.7rem;
            color: var(--black);
            margin-bottom: 2.8rem;
        }

        .custom-html-elements {
            iframe {
                width: 100%;
                height: 58rem;
                margin: 2.5rem 0;
            }

            p {
                margin-bottom: 1.2rem;
                font-size: var(--p-larger);
                line-height: 2.7rem;
                color: var(--dark-gray);
            }
        }

        .custom-sv-header {
            .custom-sv-title {
                font-size: var(--h2);
                font-weight: var(--font-weight-regular);
                line-height: 3.7rem;
                color: var(--black);
                margin-bottom: 2.8rem;
            }
        }

        .custom-sv-question-title {
            font-size: var(--h4);
            line-height: 2.7rem;
            color: var(--black);
            padding-bottom: 2rem;
            border-bottom: 2px solid var(--light-gray);
            display: flex;
            align-items: flex-end;
            min-height: calc((27px * 2) + 20px + 2px);
            margin-top: 2.4rem;

            @media (--tablet) {
                min-height: calc((27px * 2) + 32px + 2px);
                padding-bottom: 3.2rem;
            }
        }

        .custom-sv-radiogroup-item {
            margin-bottom: 2rem;
        }

        .custom-sv-radiogroup {
            font-size: var(--p-larger);
            line-height: 2.7rem;
            font-weight: var(--font-weight-light);
            color: var(--dark-gray);
            display: flex;
            align-items: center;

            svg {
                display: none;
            }

            input[type="radio"] {
                display: none;
            }
            input[type="radio"] + *::before {
                content: "";
                display: inline-block;
                vertical-align: bottom;
                width: 2rem;
                height: 2rem;
                margin-right: 1.2rem;
                border-radius: 50%;
                border-style: solid;
                border-width: 1px;
                border-color: var(--mid-gray);
            }
            input[type="radio"]:checked + * {
                color: var(--petrol);
            }
            input[type="radio"]:checked + *::before {
                background: radial-gradient(
                    var(--petrol) 0%,
                    var(--petrol) 40%,
                    transparent 50%,
                    transparent
                );
                border-color: var(--petrol);
            }
        }

        .sv-ranking {
            margin-top: 10rem;
            padding-bottom: 5rem;

            @media screen and (max-height: 700px) {
                margin-top: 3rem;
                padding-bottom: 2rem;
            }

            .sv-ranking-item {
                margin-bottom: 2rem;

                &__icon-container {
                    width: 100%;

                    svg {
                        display: none;
                    }
                }

                &__content {
                    padding: 0;
                    display: flex;
                    align-items: center;
                }

                &__text {
                    font-size: var(--p);
                    color: var(--dark-gray);
                    margin-left: 1.2rem;
                    font-weight: var(--font-weight-light);
                }

                &__index {
                    background-color: var(--white);
                    padding: 0.7rem 1.3rem;
                    border: 1px solid var(--orange);
                    border-radius: 100px;
                    width: 3.6rem;
                    height: 3.6rem;
                    flex: 0 0 3.6rem;
                    color: var(--orange);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                }

                &--ghost {
                    background-color: var(--light-gray);
                    border-radius: 30px;
                    padding: 1rem;

                    .custom-sv-item__content {
                        opacity: 0;
                    }
                }

                &__ghost {
                    display: none;
                }
            }
        }

        .custom-sv-ranking-drag {
            &--drag {
                background-color: var(--white);
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
                border-radius: 30px;
                padding: 1rem;
            }
        }

        .custom-sv-footer {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            background: var(--white);
            border-radius: 3.2rem 3.2rem 0 0;
            padding: 2rem;
            filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
            display: flex;
            z-index: 199;

            @media (--tablet) {
                left: 50%;
                width: calc(100% - var(--narrow-page-padding));
                filter: none;
                border-radius: 0;
                transform: translateX(-50%);
                border-top: 2px solid var(--light-gray);
                display: flex;
                justify-content: center;

                input {
                    max-width: 30rem;
                }
            }

            .custom-sv-next,
            .custom-sv-prev,
            .custom-sv-complete {
                border-radius: 3.2rem;
                padding: 1.5rem 0;
                font-size: var(--p-larger);
                line-height: 2.7rem;
                margin: 0;
                font-family: var(--font-family);
                font-weight: var(--font-weight-regular);
            }

            .custom-sv-next,
            .custom-sv-complete {
                background-color: var(--petrol);
                border: none;
                color: var(--white);
                margin-left: 1.5rem;

                &:hover {
                    background-color: var(--petrol);
                    opacity: 0.6;

                    @media (hover: none) {
                        opacity: 1;
                    }
                }

                &--disabled {
                    background-color: var(--light-gray);
                    color: var(--mid-gray);

                    &:hover {
                        opacity: 1;
                        background-color: var(--light-gray);
                        cursor: auto;
                    }
                }
            }

            .custom-sv-prev {
                background-color: var(--white);
                border: 2px solid var(--petrol);
                color: var(--petrol);

                &:hover {
                    background-color: var(--white);
                    opacity: 0.6;

                    @media (hover: none) {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

#custom-span {
    font-size: var(--h1);
    text-align: center;
    z-index: 1;
    color: var(--black);
    margin-bottom: 4.4rem;
    max-width: var(--narrow-page-width);
}

@media screen and (max-width: 700px) {
    #custom-span {
        font-size: normal;
        max-width: 85%;
    }
}