.pwc-results-container {
  margin: 2rem 0 5rem;
  @media screen and (min-width: 800px) {
    margin: 4rem 0 0;
  }
  h1 {
    line-height: normal;
    font-size: 2rem;
    margin: 1rem 0 2rem 0;
  }
  .c-style-graph {
    margin: 8rem auto 4rem auto;
    @media screen and (min-width: 800px) {
      margin: 10rem auto 7rem auto;

      .labels__facts {
        bottom: -6rem;
      }
    }
    .dot-here-text {
      font-size: 1.5rem;
      line-height: normal;
    }
  }
  .video-intro-text {
    padding: 1rem 0 2rem;
  }
  .video-container {
    text-align: center;
  }
}