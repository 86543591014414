.flip-card {
    margin-top: 4rem;
    filter: drop-shadow(0px 0px 0.5rem rgba(0, 0, 0, 0.15));

    &:last-of-type {
        margin-bottom: 8rem;

        @media (--tablet) {
            margin-bottom: 4rem;
        }
    }

    .front,
    .back {
        border-radius: 12px;
        background-color: var(--white);
        height: 42rem;
        overflow: hidden;
        cursor: pointer;

        @media (--tablet) {
            height: 50.2rem;
        }

        &:before {
            content: "";
            width: 100%;
            height: 12rem;
            position: absolute;
            bottom: 7.8rem;
            left: 0;
            background: var(--gradient-white);
        }

        &:after {
            content: "";
            width: calc(100% - 4rem);
            height: 2px;
            background-color: var(--light-gray);
            position: absolute;
            bottom: 7.8rem;
            left: 2rem;
        }

        &__content {
            padding: 3.6rem 2.2rem 9rem 2.2rem;
            width: 100%;
            height: 100%;
            overflow-y: scroll;

            .card-title {
                line-height: normal;
                font-size: 2rem;
                margin-bottom: 2rem;
                @media screen and (min-width: 600px) {
                    font-size: 2.4rem;
                }
            }
            .card-content {
                p {
                    font-size: 1.6rem !important;
                }

                ul {
                    margin: 0;
                    list-style: disc;
                    padding-left: 1rem;
                    padding-bottom: 5rem;

                    li {
                        font-size: 1.5rem;
                        margin: 0.5rem 0;
                    }
                }
                @media screen and (min-width: 600px) {
                    p {
                        font-size: 2rem !important;
                    }
                    ul {
                        padding-left: 2rem;

                        li {
                            margin: 1rem 0;
                            font-size: 1.9rem;
                        }
                    }
                }
            }
        }

        &__cta {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 7.8rem;
            padding: 0 2rem;
            background-color: var(--white);
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            border-radius: 0px 0px 12px 12px;

            &:before {
                content: "Flip card";
                color: var(--orange);
                font-size: var(--p);
                line-height: 2rem;
                letter-spacing: 0.1em;
                text-transform: uppercase;
            }

            &:after {
                content: url("../../assets/images/flip.svg");
            }
        }
    }

    .front,
    .back {
        p:first-of-type {
            font-size: var(--h4);
            line-height: 2.7rem;
            font-weight: var(--font-weight-regular);
            color: var(--black);
            margin-bottom: 2.5rem;
        }

        p:last-of-type {
            padding-bottom: 8rem;
        }
    }

    .back {
        p,
        p:first-of-type {
            color: var(--petrol);
        }
    }
}

.jsx-parser {
    &.flip-cards {
        p {
            font-size: var(--p-larger);
            line-height: 2.7rem;

            @media (--tablet) {
                line-height: 3.2rem;
            }
        }

        .sv_row & {
            padding: 0 0.7rem;
        }
    }
}
