.inter-screen-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1001;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--gutter);

  .inter-screen {
    width: 100%;

    &__header {
      font-size: 3rem;
      font-weight: 900;
      line-height: 1.3;
      margin: 4rem 0;
    }

    &__timer {
      h1 {
        font-size: 2.2rem;
        line-height: 150%;
      }

      &__user-number {
        font-size: 3rem;
      }
    }

    &__job-survey {
      margin-top: 2rem;

      &__title {
        font-size: 2.2rem;
        line-height: 150%;
      }
    }

    @media screen and (min-width: 800px) {
      max-width: 66rem;
      margin: 0 auto;
      transform: translate(0);

      &__header {
        text-align: center;
      }

      &__timer {
        text-align: center;
      }

      &__job-survey {
        margin: 10rem 0;
      }
    }
  }
}

.envelope-btn {
  display: flex !important;
  justify-content: center;
  align-items: center;

  svg {
    width: 3rem;
    margin-right: .5rem;
  }
}