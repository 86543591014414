.modal {
    background-color: rgba(255, 255, 255, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1002;

    &--cstyle {
        .modal__panel {
            .page-wrapper {
                padding-top: 0;
            }
        }
    }

    &--iframe {
        .modal__panel {
            height: 100%;
        }
    }

    &__panel {
        &::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
        background-color: var(--white);
        padding-bottom: 2rem;
        width: 100%;
        max-height: 80%;
        overflow-y: scroll;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 32px 32px 0px 0px;
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);

        @media (--tablet) {
            top: 50%;
            left: 50%;
            bottom: auto;
            transform: translate(-50%, -50%);
            width: 58.4rem;
            border-radius: 24px;
        }

        &__heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: var(--white);
            padding: 3.6rem 2rem 2rem 2rem;
            position: sticky;
            top: 0;
            left: 0;
            z-index: 1;

            .subtitle {
                color: var(--petrol);
            }

            h4 {
                color: var(--petrol);
            }

            &__close {
                cursor: pointer;
            }

            @media (--tablet) {
                margin-left: var(--gutter);
                margin-right: var(--gutter);
                padding-left: 0;
                padding-right: 0;
                border-bottom: 0.2rem solid var(--light-gray);
            }
        }

        &__gradient {
            width: 100%;
            height: 12rem;
            position: sticky;
            bottom: 0;
            left: 0;
            background: var(--gradient-white);
        }

        iframe {
            width: 100%;
            height: 100%;

            @media (--tablet) {
                padding: 0 1rem;
            }
        }
    }

    .generic-text-page .page-wrapper,
    .generic-content .page-wrapper {
        padding-top: 1.2rem;

        @media (--tablet) {
            padding-top: 2.4rem;
        }
    }
}