.not-found {
    @media (--tablet) {
        margin: 0 auto;
        max-width: var(--narrow-page-width);
    }

    h2 {
        margin: 1.7rem 0 2rem 0;
        @media (--tablet) {
            font-size: 5.5rem;
            line-height: 6rem;
        }
    }

    h3 {
        color: var(--dark-gray);
        margin-bottom: 4.4rem;
    }

    hr {
        margin-bottom: 2rem;
        @media (--tablet) {
            margin-bottom: 3.2rem;
        }
    }

    a {
        text-decoration: underline;
        font-size: var(--p-larger);
        line-height: 2.7rem;
    }
}
