.c-style-graph {
    margin: 4rem auto;

    @media (--tablet) {
        margin: 6rem auto;
    }

    width: 100%;
    height: 0;
    padding-top: 79%;
    position: relative;

    @media (--tablet) {
        padding-top: 42%;
    }

    h3 {
        font-size: var(--h4);
        line-height: 2.7rem;

        @media (--tablet) {
            font-size: var(--h3);
            line-height: 3.4rem;
        }
    }

    &-wrapper {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        cursor: pointer;

        &::before {
            content: "";
            position: absolute;
            left: calc(50% - 0.1rem);
            top: 0;
            bottom: 0;
            width: 0.2rem;
            background: #e1e1e2;
        }

        &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: calc(50% - 0.1rem);
            height: 0.2rem;
            background: #e1e1e2;
        }

        .labels {
            position: absolute;
            background-color: var(--white);
            z-index: 1;
            color: var(--mid-gray);
            font-size: var(--p);
            line-height: 2.5rem;

            &__introvert,
            &__extrovert {
                top: calc(50% - 1.4rem);
            }

            &__introvert,
            &__facts {
                padding-right: 0.5rem;
            }

            &__extrovert,
            &__people {
                padding-left: 0.5rem;
            }

            &__introvert {
                left: 0;
            }

            &__extrovert {
                right: 0;
            }

            &__facts,
            &__people {
                transform-origin: left;
                left: calc(50% - 0.1rem);
                transform: rotate(-90deg);
            }

            &__facts {
                bottom: -2rem;
            }

            &__people {
                top: 3.4rem;
            }
        }

        .handleLongExtrovert {
            width: 10rem;
            transform: translateX(-2rem);

            @media (--tablet) {
                width: 12rem;
                transform: translateX(-3rem);
            }
        }

        .connecting,
        .influencing,
        .examining,
        .delivering {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            color: var(--petrol);
            transition: 0.5s;
        }

        .connecting:hover,
        .influencing:hover,
        .examining:hover,
        .delivering:hover {
            transform: scale(1.05);
            transition: 0.5s;
        }

        .influencing,
        .delivering {
            align-items: flex-end;
        }

        .connecting,
        .influencing {
            margin-top: -1rem;
        }

        .examining,
        .delivering {
            justify-content: flex-end;
            margin-bottom: -1rem;
        }

        p.smaller {
            color: var(--orange);
        }

        &__dot-boundary {
            position: absolute;
            top: 7rem;
            right: 7rem;
            left: 7rem;
            bottom: 7rem;
            z-index: 2;
        }

        .dot {
            position: absolute;
            border-radius: 50%;
            background: rgba(255, 110, 69, 1);
            transform: translate(-0.8rem, -0.8rem);
            z-index: 1;

            &::before,
            &::after {
                content: "";
                position: absolute;
                height: 100%;
                width: 100%;
                background-color: inherit;
                /* background: rgba(255, 110, 69, 1); */
                border-radius: 50%;
                z-index: 0;
                opacity: 0.7;
            }
        }

        .big-dot {
            z-index: 35;
            width: 2.5rem;
            height: 2.5rem;
        }

        .mini-dot {
            width: 1.5rem;
            height: 1.5rem;
            border: 1px solid black;
            transform: scale(1);
            transition: 1s;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .go-big-dot {
            transform: scale(180%);
        }

        .go-small-dot {
            animation-name: animate-go-small-dot;
            animation-duration: .5s;
            animation-fill-mode: forwards;
            animation-timing-function: ease-in;
        }

        .animate-dot {
            &::before {
                animation: pulse 2s ease-out infinite;
            }

            &::after {
                animation: pulse 2s 1s ease-in-out infinite;
            }
        }

        @keyframes pulse {
            100% {
                transform: scale(2);
                opacity: 0;
            }
        }

        @keyframes animate-go-big-dot {
            0% {
                transform: scale(1);
            }

            50% {
                transform: scale(2.5);
            }

            100% {
                transform: scale(2);
            }
        }

        @keyframes animate-go-small-dot {
            0% {
                transform: scale(2);
            }

            50% {
                transform: scale(2.5);
            }

            100% {
                transform: scale(1);
            }
        }
    }
}

.label-container {
    position: relative;
    width: 80%;
    height: calc(var(--h3) + 20px);
    overflow: hidden;
    border-radius: 50px;
    transform: translate(-20px -20px);
}

.labels-mutual {
    position: absolute;
    height: 1200px;
    width: 1200px;
}

.labels-text {
    width: 100%;
    height: 100%;
    color: white;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    &__title {
        font-size: 2rem !important;
        font-weight: 600;
        text-align: center;
    }

    &__icon {
        display: flex;
        align-items: center;
        font-size: 3rem;
        font-weight: 400;

        svg {
            text-shadow: 0 0 5px white;
        }
    }
}

@media screen and (max-width: 500px) {
    .label-container {
        width: 90%;
        height: calc(1.5rem + 25px);

        .labels-text {
            &__title {
                font-size: 1.5rem !important;
                width: auto;
            }

            &__icon {
                font-size: 2rem;
                padding-right: 0.5rem;
            }
        }
    }
}

.numbers-container {
    width: calc(var(--h3) + 12px);
    height: calc(var(--h3) + 12px);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    &__text {
        font-weight: 900;
        color: white;
        position: absolute;
    }
}

.labels-video-player {
    display: none;
}

.labels-video-playing {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-container {
    position: relative;

    .video-close {
        position: absolute;
        right: 1rem;
        top: 1rem;
        cursor: pointer;
    }
}

.dot-container {
    position: absolute;
}

.mini-dot-container {
    width: 20px;
    height: 20px;
    position: absolute;

    .dot-user-number {
        z-index: 30;
        color: white;
        font-size: 1rem;
        font-weight: 600;
    }
}

.dot-here-text {
    position: relative;
    top: 2.7rem;
    left: -1.5rem;
    width: 5rem;
    font-weight: bold;
    z-index: 2;

    @media screen and (min-width: 800px) {
        width: 6rem;
    }
}

.hide {
    display: none !important;
}

.show {
    display: block;
}