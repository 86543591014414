.play-pause-button {
    cursor: pointer;
    .icon {
        transition: opacity 0.5s;
    }

    .play-icon {
        opacity: 1;
    }
    .pause-icon {
        opacity: 0;
    }

    &.is-playing {
        .play-icon {
            opacity: 0;
        }
        .pause-icon {
            opacity: 1;
        }
    }
}
