.page-wrapper {
    padding-top: 20px;
    padding-bottom: 0;

    .static-text {
        font-size: var(--p-larger);
        margin-top: 2rem;
    }
}
@media screen and (min-width: 700px) {
    .page-wrapper {
        overflow: hidden;
    }
}

.c-style-results {
    @media (--tablet) {
        max-width: var(--wide-page-width);
        margin: 0 auto;

        .action-panel {
            button {
                margin: 0 auto;
            }
        }
    }

    p,
    h2 {
        margin-bottom: 1.2rem;
    }

    h2 {
        margin-top: 1.2rem;
        @media (--tablet) {
            padding-bottom: 3.2rem;
            margin-bottom: 4.8rem;
            border-bottom: 4px solid var(--light-gray);
        }

        .button-primary--remake {
            display: none;
            @media (--tablet) {
                display: inline-block;
                float: right;
            }
        }
    }

    a {
        text-transform: uppercase;
        font-size: var(--subtitle);
        line-height: 1.6rem;
        letter-spacing: 0.1em;
        margin-bottom: 1.2rem;
    }

    .c-style-graph {
        margin: 4rem auto;

        @media (--tablet) {
            margin: 6rem auto;
        }

        width: 100%;
        height: 0;
        padding-top: 79%;
        position: relative;

        @media (--tablet) {
            padding-top: 42%;
        }

        h3 {
            font-size: var(--h4);
            line-height: 2.7rem;

            @media (--tablet) {
                font-size: var(--h3);
                line-height: 3.4rem;
            }
        }

        &-wrapper {
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            cursor: pointer;

            &::before {
                content: "";
                position: absolute;
                left: calc(50% - 0.1rem);
                top: 0;
                bottom: 0;
                width: 0.2rem;
                background: #e1e1e2;
            }
            &::after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: calc(50% - 0.1rem);
                height: 0.2rem;
                background: #e1e1e2;
            }

            .labels {
                position: absolute;
                background-color: var(--white);
                z-index: 1;
                color: var(--mid-gray);
                font-size: var(--p);
                line-height: 2.5rem;

                &__introvert,
                &__extrovert {
                    top: calc(50% - 1.4rem);
                }

                &__introvert,
                &__facts {
                    padding-right: 0.5rem;
                }

                &__extrovert,
                &__people {
                    padding-left: 0.5rem;
                }

                &__introvert {
                    left: 0;
                }

                &__extrovert {
                    left: calc(100% - 6.8rem);

                    @media (--tablet) {
                        left: calc(100% - 7.6rem);
                    }
                }

                &__facts,
                &__people {
                    transform-origin: left;
                    left: calc(50% - 0.1rem);
                    transform: rotate(-90deg);
                }

                &__facts {
                    bottom: -2rem;
                }

                &__people {
                    top: 3.4rem;
                }
            }

            .connecting,
            .influencing,
            .examining,
            .delivering {
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                color: var(--petrol);
            }

            .influencing,
            .delivering {
                align-items: flex-end;
            }

            .connecting,
            .influencing {
                margin-top: -1rem;
            }

            .examining,
            .delivering {
                justify-content: flex-end;
                margin-bottom: -1rem;
            }

            p.smaller {
                color: var(--orange);
            }

            &__dot-boundary {
                position: absolute;
                top: 7rem;
                right: 7rem;
                left: 7rem;
                bottom: 7rem;
            }
            .dot {
                position: absolute;
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 50%;
                background: rgba(255, 110, 69, 1);
                transform: translate(-.8rem, -.8rem);
                z-index: 1;

                &::before, &::after {
                    content: "";
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    background: rgba(255, 110, 69, 1);
                    border-radius: 50%;
                    z-index: 0;
                    opacity: .7;
                }

                &::before {
                    animation: pulse 2s ease-out infinite;
                }

                &::after {
                    animation: pulse 2s 1s ease-in-out infinite;
                }
            }
            @keyframes pulse {
                100% {
                    transform: scale(2);
                    opacity: 0;
                }
            }
        }
    }

    .location-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .retake-btn {
        width: 50%;
        text-align: right;
    }

    .c-style-name-container {
        margin: 20px 0;
    }
    @media screen and (max-width: 700px) {
        .result_quadrant__header {
            height: 10rem;
        }
        .result_quadrant__copy {
            padding: 1.5rem 2rem 0;
        }
    }
    @media screen and (min-width: 700px) {
        .result_quadrant__header {
            height: 11rem !important;
        }
        .result_quadrant__copy {
            padding: 2rem;

            p {
                text-transform: uppercase;
                margin-bottom: 5px;
            }
        }
    }
    @media screen and (min-width: 1070px) {
        .result_quadrant__copy {
            padding-top: 2rem !important;
        }
    }

    .labels-video-player {
        display: none;
    }
    .labels-video-playing {
        top: 0;
        left: 0;
        position: fixed;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
        background: rgba(255,255,255,.8);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .video-container {
        position: relative;

        .video-close {
            position: absolute;
            right: 1rem;
            top: 1rem;
            cursor: pointer;
        }
    }
    


    .profile-c-style-span {
        color: black;
        font-weight: bold;
        font-size: var(--subtitle);
    }
    .profile-c-style-span::before {
        content: '>';
        color: grey;
        padding: 0 5px;
    }
    .c-style-banner-text {
        position: absolute;
        top: 0;
        left: 0;
        color: white;
        padding: 6.5rem 2rem 0;
    }
}
.result_quadrant {
    &__header {
        height: 16rem;
        overflow: hidden;
        position: relative;
        top: 0; 
        left: 0;
        margin-bottom: 2.8rem;
        
        svg {
            width: 130%;

            @media (--desktop) {
                transform: scale(1.8) translateY(+23%);
            }
        }
    }

    &__copy {
        position: absolute;
        top: 0;
        left: 0;
        color: white;
        padding: 6.5rem 2rem 0;

        h6.smaller {
            text-transform: uppercase;
            font-size: 1.5rem;
            padding-top: 1rem;
        }

        h1 {
            text-transform: capitalize;
        }
    }

    .profile-c-style-span {
        color: black;
        font-weight: bold;
        font-size: var(--subtitle);
    }
    .profile-c-style-span::before {
        content: '>';
        color: grey;
        padding: 0 5px;
    }
    .c-style-banner-text {
        position: absolute;
        top: 0;
        left: 0;
        color: white;
        padding: 6.5rem 2rem 0;
    }
}

.main-zone-paragraph {
    h1 {
        font-size: 2.2rem;
        font-weight: 600;
        margin: 2.5rem 0 .8rem;
        line-height: normal;
    }
    .phone-view-video-container {
        display: none !important;
    }
}