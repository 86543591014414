.radio {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(var(--survey-min-height) - 12rem);

    h5 {
        text-transform: capitalize;

        &.selected {
            color: var(--petrol);
        }
    }

    &__wrapper {
        text-align: center;
        width: 100%;
        height: 15rem;
        display: flex;
        align-items: center;
        padding: 0 0.6rem;

        @media (--tablet) {
            padding: 0 10rem;
        }
    }

    &__bar {
        width: 100%;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            background-color: var(--gray);
            height: 0.8rem;
            width: 98%;
            top: 50%;
            left: 2px;
            transform: translateY(-50%);
        }
    }

    &__choices {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 100%;
        width: 100%;
        cursor: pointer;
        height: 100%;

        &__dots-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            &--label {
                position: absolute;
                top: 5rem;
                color: var(--dark-gray);
                font-size: 1.4rem;
                line-height: 2rem;
                text-transform: capitalize;
                width: 40%;

                @media (--tablet) {
                    width: 20rem;
                }
            }

            &--label[data-index="0"] {
                left: -1rem;

                @media (--tablet) {
                    left: auto;
                }
            }
            &--label[data-index="1"],
            &--label[data-index="3"] {
                right: -.5rem;

                @media (--tablet) {
                    right: auto;
                }
            }
        }

        &--dot {
            border-radius: 50%;
            position: relative;
            z-index: 10;
            width: 2rem;
            height: 2rem;
            background-color: var(--gray);
            border: 2px solid var(--white);
            cursor: pointer;

            &--selected {
                transform-origin: center;
                transform: scale(1.6);
                background-color: var(--orange);
            }

            &:hover {
                @media (--desktop) {
                    border: none;
                    transform-origin: center;
                    transform: scale(1.6);
                    background-color: var(--orange);
                }

                &:after {
                    @media (--desktop) {
                        content: "";
                        width: 2.5rem;
                        height: 2.5rem;
                        border: 2px solid var(--orange);
                        border-radius: 100px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
    }
}
