.certificate-view {
    .page-wrapper {
        padding-top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__background {
        background-size: cover;
        background-repeat: no-repeat;
        padding: 1rem;
        width: 100%;

        @media (--tablet) {
            width: 35rem;
        }
    }

    &__content {
        background-color: var(--white);
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2.4rem 0;

        &__logo {
            width: 6.1rem;
            height: 3.3rem;
            margin-bottom: 6.4rem;

            svg {
                width: 100%;
                height: 100%;

                path {
                    fill: var(--orange);
                }
            }
        }

        h4 {
            margin-bottom: 5.6rem;
        }

        h4:first-of-type {
            margin-bottom: 3.2rem;
        }

        &__ribbon {
            margin-bottom: 3.8rem;
        }

        p {
            &.subtitle {
                margin-bottom: 0.4rem;

                &:first-of-type {
                    margin-bottom: 0.7rem;
                }
            }
        }

        p + p {
            margin-top: 0;
        }
    }

    button {
        margin-top: 2rem;
        width: calc(100% - (var(--gutter) * 4));
    }
}
