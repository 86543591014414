.topics-detail {
    /* padding-bottom: 10rem; */

    hr {
        margin-top: 2.8rem;
        margin-bottom: 2.8rem;
        @media (--tablet) {
            max-width: calc(var(--wide-page-width) - 2 * var(--gutter));
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__title {
        margin-top: 1.6rem;
        margin-bottom: 2rem;
        @media (--tablet) {
            max-width: calc(var(--wide-page-width) - 2 * var(--gutter));
            margin-left: auto;
            margin-right: auto;
        }
        button {
            display: none;
        }

        @media (--tablet) {
            padding-bottom: 3.2rem;
            border-bottom: 4px solid var(--light-gray);
            margin-bottom: 4.8rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
                display: inline-block;
                width: 17rem;
            }
        }
    }

    &__tile {
        cursor: pointer;
        @media (--tablet) {
            max-width: calc(var(--wide-page-width) - 2 * var(--gutter));
            margin-left: auto;
            margin-right: auto;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            hr {
                display: none;
            }
        }

        .topics-tile {
            &__item {
                flex-direction: column-reverse;

                &__title {
                    font-size: 2.5rem;
                    line-height: 3.4rem;
                }

                &__description {
                    display: none;
                }
            }
        }

        .progress-bar {
            margin-top: -0.2rem;

            @media (--tablet) {
                margin-top: 1.6rem;
            }
        }
    }

    &__back-link {
        text-transform: uppercase;
        font-size: var(--subtitle);
        line-height: 1.6rem;
        letter-spacing: 0.1em;
        color: var(--orange);
        font-weight: var(--font-weight-regular);

        @media (--tablet) {
            display: block;
            max-width: calc(var(--wide-page-width) - 2 * var(--gutter));
            margin-left: auto;
            margin-right: auto;
        }
    }
    &__image {
        @media (--tablet) {
            flex: 1 1 33%;
        }

        @media (--big-screen) {
            flex: 1 1 66%;
        }
    }

    &__summary {
        @media (--tablet) {
            padding-left: 3.2rem;
            flex: 1 1 33%;
        }
        p {
            font-style: normal;
            font-weight: var(--font-weight-regular);
            font-size: 2rem;
            line-height: 2.7rem;
            color: var(--dark-gray);
            margin-top: 1.2rem;
        }
    }

    .section-with-badge {
        &:first-of-type {
            margin-top: 2.8rem;
            padding-bottom: 11rem;
        }

        &__title {
            font-size: 2.5rem;
            line-height: 3.4rem;

            @media (--tablet) {
                font-size: 3.5rem;
                line-height: 4.2rem;
                max-width: calc(var(--wide-page-width) - 2 * var(--gutter));
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .page-wrapper {
        @media (--tablet) {
            padding: calc(var(--gutter) * 2) var(--gutter) 0 0;
        }
    }

    .action-panel {
        @media (--tablet) {
            display: none;
        }
    }
}
