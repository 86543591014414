.profile {
    margin: 0 auto;

    padding-bottom: 10rem;

    @media (--tablet) {
        max-width: var(--narrow-page-width);
    }

    .action-panel {
        @media (--tablet) {
            display: none;
        }
    }

    p {
        &.subtitle {
            margin-bottom: 1.2rem;
        }

        &.larger {
            color: var(--dark-gray);
            margin-bottom: 1.2rem;

            @media (--tablet) {
                font-size: var(--h4);
                line-height: 1.4;
            }

            &.cta {
                color: var(--orange);
                text-decoration: underline;
                margin-top: 2rem;
                cursor: pointer;
            }
        }
    }

    &__info {
        h2 {
            margin-bottom: 0.4rem;
            @media (--tablet) {
                font-size: var(--h1-big);
                line-height: 1.4;
            }
        }

        h4 {
            color: var(--dark-gray);
            @media (--tablet) {
                font-size: var(--h2);
                line-height: 1.4;
                margin-bottom: 4rem;
            }
        }
    }

    &__c-style {
        h3 {
            margin-bottom: 1.3rem;
            @media (--tablet) {
                font-size: var(--h2);
                line-height: 1.4;
            }
        }
    }

    hr {
        width: 100%;
        max-width: 100%;
        margin: 2.8rem 0;

        @media (--tablet) {
            margin: 4rem 0;
            border-width: 0.4rem;
        }
    }

    &__certificates {
        span {
            color: var(--orange);
        }
    }

    .result_quadrant {
        p {
            color: var(--dark-gray);
        }
    }

    .result_quadrant__header {
        height: auto;
        margin-left: 0;
        margin-right: 0;
    }

    .result_quadrant__background {
        display: none;
    }

    .result_quadrant__copy {
        padding: 0;
        position: static;
        color: var(--black);

        p {
            display: none;
        }
    }

    .result_video {
        display: none !important;
    }
    .phone-view-video-container {
        display: none !important;
    }
}
