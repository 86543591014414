.progress-bar {
    position: relative;
    /* font-size: 2.5rem; */

    &__time-left {
        font-style: normal;
        font-weight: var(--font-weight-regular);
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: var(--dark-gray);
    }
    &__time-right {
        position: absolute;
        right: 0;
        bottom: 0rem;
        color: var(--orange);
        font-size: 2rem;
        line-height: 2.7rem;
    }

    &__percentage {
        font-style: normal;
        font-weight: var(--font-weight-regular);
        font-size: 20px;
        line-height: 27px;
        flex: 1 1 50%;
        color: var(--orange);
        cursor: pointer;

        &--percent {
            margin-right: 1.5rem;
        }
        &--certified {
            color: var(--petrol);
            display: flex;
            margin-top: 1rem;

            &__icon {
                margin-right: 1rem;
                width: 2.8rem;
                height: 3.3rem;
            }
        }

        &--label {
            font-size: 1.3rem;
            line-height: 1.8rem;
            color: var(--dark-gray);
            opacity: 0.5;
        }
    }

    &__value {
        flex-shrink: 1;
        margin-bottom: 0.2em;

        font-size: 2rem;
        line-height: 2.7rem;
        color: var(--orange);

        &--certified {
            margin-top: 1rem;
        }
    }

    &__wrapper {
        margin: 10px 0;
        width: 100%;
        background-color: var(--light-gray);
    }

    &__bar {
        transition: width 0.5s;
        width: 0%;
        min-width: 0.24em;
        height: 0.6rem;

        background-color: var(--orange);
    }

    &__info-row {
        display: flex;
        flex-flow: row nowrap;
    }
}
