.flags-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  
  
  .flag {
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4rem;
      height: 4rem;
      position: relative;
      
      &:hover {
          opacity: .7;
      }
  }

  .selected {
      border: 2px solid white;
  }
}