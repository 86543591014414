.pwc-results {
  background: white;
  @media (--tablet) {
    max-width: var(--wide-page-width);
    margin: 0 auto;

    .action-panel {
        button {
            margin: 0 auto;
        }
    }
  }
  a {
    text-transform: uppercase;
    font-size: var(--subtitle);
    line-height: 1.6rem;
    letter-spacing: 0.1em;
    margin-bottom: 1.2rem;
  }
  .location-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .retake-btn {
      width: 50%;
      text-align: right;
      
      button {
        width: auto;
        padding-left: 1.2rem;
        padding-right: 1.2rem;

        @media screen and (min-width: 500px) {
          width: 90%;
        }
      }
  }
  .profile-span {
    color: black;
    font-weight: bold;
    font-size: var(--subtitle);

    &::before {
      content: '>';
      color: grey;
      padding: 0 5px;
    }
  }
}

.long {
  display: none;
}
@media screen and (min-width: 1220px) {
  .short {
    display: none;
  }
  .long {
    display: inline;
  }
}