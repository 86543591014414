.trueOrFalse {
  height: var(--survey-min-height);
  display: flex;
  align-items: center;

  div {
    .header {
      &__title {
        font-size: 2.2rem;
        line-height: 1.1;
      }

      &__subtitle {
        font-style: italic;
        padding: 1rem 0;
      }
    }

    .buttons-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 3rem 0;

      .btn-container {
        border: 2px solid white;
        padding: 3px 3px;
        margin: 0 0.5rem;
      }

      .btn {
        min-width: 5rem;
        height: 3rem;
        cursor: pointer;
        font-weight: 400;
        border-radius: 4px;

        border: 1px solid var(--dark-gray);
        color: var(--dark-gray);
        background-color: white;


      }

      .btn-hover:hover {
        color: white;
        background-color: var(--dark-gray);
      }

      .correct {
        border: 1px solid #1ab394;
        color: white;
        background-color: #1ab394;
      }

      .incorrect {
        border: 1px solid #d93f02;
        color: white;
        background-color: #d93f02;
      }

      .isUserPick {
        border: 2px solid;
        border-radius: 4px;
      }
    }

    .reveal-hid {
      visibility: hidden;
    }

    .reveal {
      animation-name: fadeIn;
      animation-duration: 0.5s;
      animation-iteration-count: 1;
      animation-timing-function: ease-in;
    }

  }
}

@keyframes fadeIn {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}