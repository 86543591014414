.action-panel {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--white);
    border-radius: 3.2rem 3.2rem 0 0;
    padding: 2rem;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
    transition: transform 0.5s, opacity 0.5s;
    transform: translate3d(0, 100%, 0);
    opacity: 0;
    overflow: hidden;
    z-index: 1;

    @media (--tablet) {
        left: 50%;
        bottom: 4rem;
        width: 58rem;
        border-radius: 3.2rem;
        transform: translate3d(-50%, 100%, 0);
        text-align: center;
    }

    &--visible {
        transform: translate3d(0, 0, 0);
        opacity: 1;

        @media (--tablet) {
            transform: translate3d(-50%, 0, 0);
        }
    }

    &--player-is-visible {
        padding-bottom: 12rem;
    }

    .podcast-player__close-button {
        position: absolute;
        top: 0.5rem;
        right: 2rem;
        cursor: pointer;
        padding: 0.5rem 0 0 0.5rem;

        svg {
            path {
                fill: var(--dark-gray);
            }
        }
    }

    .podcast-player__minify-button {
        position: absolute;
        top: 0.5rem;
        left: 2rem;
        cursor: pointer;
        padding: 0.5rem 0 0 0.5rem;

        svg {
            path {
                fill: var(--dark-gray);
            }
        }
    }

    &--for-player {
        &--expanded {
            padding: 0;
            z-index: 9999;

            border-radius: 3.2rem;
            left: 2rem;
            right: 2rem;
            top: 2rem;
            bottom: 2rem;
            transform: translate3d(0, 0, 0);
            overflow: visible; /* explicitly set overflow visible for Safari */
            mask-image: radial-gradient(white, black);

            @media (--tablet) {
                left: 50%;
                right: 0;
                top: 2rem;
                bottom: 2rem;
                transform: translate3d(-50%, 0, 0);
            }

            .podcast-player__close-button {
                top: 3.4rem;
                right: 3.4rem;

                svg {
                    path {
                        fill: white;
                    }
                }
            }

            .podcast-player__minify-button {
                top: 3.4rem;
                left: 3.4rem;
                svg {
                    path {
                        fill: transparent;
                    }
                }
            }
        }

        &--wrapper {
            width: 100%;
            height: 100%;
            background: var(--black);
        }
    }

    .results_button_panel {
        display: flex;
        flex-direction: row;

        button + button {
            margin-left: 2rem;
        }
    }
}
