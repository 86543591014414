.course-row {
  display: flex;
  padding: 0 2%;
  border-bottom: 3px solid var(--light-gray);

  hr {
    color: blue;
    width: 3px;
    background: var(--light-gray);
    margin: 2.8rem 1.5rem !important;
  }

  .left-column {
    width: 18%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
    }
  }

  .middle-column {
    width: 40%;
    padding: 2% 0%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width:500px) {
      width: 60%;
      padding: 2% 9%;
    }

    p {
      text-align: center;
    }

    span {
      cursor: pointer;
      color: red;
      font-size: 1.5rem;
      animation-name: c-style-pulse;
      animation-duration: 4s;
      animation-iteration-count: infinite;

      @media screen and (min-width: 500px) {
        transform: translateX(0);
        padding-top: 1rem;
        font-size: 1.7rem;
      }
    }
  }

  .right-column {
    width: 18%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 2.5rem;
      width: 2.5rem;
    }
  }
}

.goBtn {
  border-radius: 40px;
  padding: 4px 10px 3px;
  color: white;
  cursor: pointer;
  border: none;
  background-color: var(--petrol);

  &:hover {
    opacity: .6;
  }
}

.no-border-bottom {
  border-bottom: none;
}

.c-style-row {
  flex-direction: column;
  padding-top: 2rem;

  @media screen and (min-width: 500px) {
    padding-top: 3rem;
  }
}

@keyframes c-style-pulse {
  10% {
    opacity: .2;
  }

  20% {
    opacity: .4;
  }

  30% {
    opacity: .6;
  }

  40% {
    opacity: .8;
  }

  50% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  70% {
    opacity: .8;
  }

  80% {
    opacity: .6;
  }

  90% {
    opacity: .4;
  }

  100% {
    opacity: 2;
  }

}