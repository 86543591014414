.cards-container {
  padding-bottom: 5rem;

  .header,
  .conclusion {
    margin: 4rem auto 0 auto;
    width: 560px;

    h1 {
      font-size: 2.2rem;
      line-height: inherit;
      margin-bottom: 2rem;
    }

    h2 {
      font-size: 2rem;
      line-height: inherit;
      opacity: 0.8;
    }
  }

  .cards-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }


  .conclusion {
    margin-top: 2rem;
  }

  @media screen and (max-width: 450px) {

    .header,
    .conclusion {
      width: 300px;

      h1 {
        font-size: 2rem;
      }

      h2 {
        font-size: 1.8rem;
      }
    }
  }
}