.contact-form-container {
  padding: 3rem 2rem 1rem 2rem;
  position: relative;

  .closeModal {
    position: absolute;
    top: 2rem;
    right: 2rem;
    cursor: pointer;
  }

  .info-text-container {
    padding: 1rem 2rem;
    margin-bottom: 1rem;
    p {
      font-size: 1.5rem;
    }
  }

  .form-wrapper {
    box-shadow: 0 0 5px rgba(0, 0, 0, .8);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow: hidden;

    .email-top-section {
      padding: 1rem 2rem;
      background-color: var(--orange);
      color: white;
    }
    .email-bottom-section {
      height: 250px;
      
      textarea {
        padding: 2rem 2rem 1rem 2rem;
        width: 100%;
        height: 100%;
        resize: none;
        border: none;
        outline: none;
        white-space: pre-wrap;
        font-size: 1.5rem;
        font-family: var(--font-family);
        background: rgb(235, 235, 235);
      }
    }
  }
  .form-send-button {
    background-color: var(--petrol);
    color: white;
    padding: 1rem 2rem;
    border-radius: 50px;
    cursor: pointer;
    margin: 2rem auto 0 auto;
    width: 20%;
    text-align: center;
    cursor: pointer;
  }
  .form-send-button:hover {
    opacity: .7;
  }


  .server-response-status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    
    svg {
      width: 8rem;
      height: 8rem;
      margin: 0rem auto;

      .cls-1 {
        fill: var(--orange);
      }
    }

    h1 {
      font-size: 2.2rem;
      margin: 2rem auto 3rem;
      line-height: normal;
      text-align: center;
    }

    button {
      padding: 1rem 3rem;
      border-radius: 4rem;
      background-color: var(--petrol);
      color: white;
      margin: 0 auto;

      &:hover {
        opacity: .7;
      }
    }
  }
}