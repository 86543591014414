.generic-content {
    .page-wrapper {
        padding-top: 1.8rem;
    }

    @media (--tablet) {
        max-width: var(--narrow-page-width);

        margin: 0 auto;
    }

    h2 {
        @media (--tablet) {
            font-size: 5.5rem;
            line-height: 6rem;
        }
    }

    h2,
    h3,
    h4 {
        margin-bottom: 2rem;

        @media (--tablet) {
            margin-bottom: 2.4rem;
        }
    }

    h3 {
        @media (--tablet) {
            font-size: 3.5rem;
            line-height: 4.2rem;
        }
    }

    h4 {
        @media (--tablet) {
            font-size: 2.8rem;
            line-height: 3.4rem;
        }
    }

    p {
        font-size: var(--p-smaller);
        line-height: 1.8rem;
        color: var(--dark-gray);
        @media (--tablet) {
            font-size: var(--p-larger);
            line-height: 3.2rem;
        }
    }

    p + h1,
    p + h2,
    p + h3,
    p + h4,
    ul + h3,
    ol + h4,
    ul + h4 {
        margin-top: 4.4rem;

        @media (--desktop) {
            margin-top: 4.8rem;
        }
    }

    ul + p,
    p + ul {
        margin-top: 2rem;
    }

    p + li,
    li + p {
        margin-top: 2rem;

        @media (--desktop) {
            margin-top: 2.4rem;
        }
    }

    li {
        color: var(--dark-gray);
        font-weight: var(--font-weight-light);
        margin-bottom: 1.2rem;
        font-size: var(--p-smaller);
        margin-top: 1.2rem;

        @media (--desktop) {
            font-size: 2.3rem;
            line-height: 3.2rem;
            margin-bottom: 1.6rem;
            margin-top: 1.6rem;
        }
    }

    ol,
    ul,
    ul li {
        margin-left: 1.2rem;

        @media (--desktop) {
            margin-left: 1.6rem;
        }
    }

    ol ol,
    ul ul {
        margin-left: 2rem;
        @media (--desktop) {
            margin-left: 2.4rem;
        }
    }

    ul {
        list-style-type: disc;
    }

    &--about-page {
        p {
            font-size: var(--p);
            line-height: 2.7rem;

            @media (--tablet) {
                font-size: 2.3rem;
                line-height: 1.4;
            }
        }

        li {
            font-size: var(--p);
            line-height: 2.7rem;

            @media (--tablet) {
                font-size: 2.3rem;
                line-height: 3.2rem;
            }
        }
    }
}
