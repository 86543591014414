.level-info-modal {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;

    svg {
        width: 100%;
        height: auto;
    }

    &__text {
        font-size: var(--h4);
        line-height: 2.7rem;
        color: var(--dark-gray);
        padding: 0 calc(var(--gutter) * 2);

        strong {
            color: var(--orange);
            font-weight: normal;
        }
    }

    &__badge-wrapper {
        position: relative;
        z-index: 2;
        flex: 0 0 12rem;
        width: 12rem;
        height: 12rem;
        transition: background-color 0.7s;
    }

    &__badge-name {
        font-size: var(--h3);
        line-height: 3.4rem;
        margin-top: 2.2rem;
        color: var(--orange);
        margin-bottom: 4.4rem;
    }

    .button-primary {
        width: calc(100% - (var(--gutter) * 4));
        margin-top: 4.4rem;
    }
}
