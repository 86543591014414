.pannel-control {
  display: flex;

  .slide-toggle {
    min-width: 45px;
    height: 22px;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 20px;
    background: rgb(219, 219, 219);
    border: 1px solid rgb(191, 189, 189);
    transition: all .4s ease-in;
    position: relative;

    .toggle-button {
      width: 20px;
      height: 20px;
      background: rgb(219, 219, 219);
      border-radius: 50%;
      border: 1px solid rgb(191, 189, 189);
      transition: all .3s ease-in;
    }

    .toggle-button-on {
      transform: translateX(120%);
    }
  }

  .slide-toggle-on {
    background-color: var(--orange);
  }

  p {
    font-weight: 400;
    font-size: 1.7rem;
  }
}