.result_quadrant {
  &__header {
      height: 16rem;
      overflow: hidden;
      position: relative;
      top: 0; 
      left: 0;
      margin-bottom: 2.8rem;
      
      svg {
          width: 130%;

          @media (--desktop) {
              transform: scale(1.8) translateY(+23%);
          }
      }
  }

  &__copy {
      position: absolute;
      top: 0;
      left: 0;
      color: white;
      padding: 6.5rem 2rem 0;

      h6.smaller {
          text-transform: uppercase;
          font-size: 1.5rem;
          padding-top: 1rem;
      }

      h1 {
          text-transform: capitalize;
      }
  }
}
.pos-absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
}