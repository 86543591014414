.video-wrapper {
    width: 14rem;
    height: 24rem;
    border-radius: 12px;
    overflow: hidden;
    position: relative;

    @media (min-width: 768px) {
        width: 23rem;
        height: 40rem;
        border-radius: 24px;
    }

    iframe {
        width: 100%;
        height: 100%;
    }

    &__overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        z-index: 2;
    }
}

.video-modal {
    background-color: rgba(255, 255, 255, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;

    &__video-wrapper {
        left: 50%;
        right: auto;
        top: 5rem;
        bottom: 5rem;
        transform: translateX(-50%);
        position: fixed;
        border-radius: 14px;
        overflow: visible; /* explicitly set overflow visible for Safari */
        mask-image: radial-gradient(white, black);

        background-color: var(--black);

        @media (max-width: 360px) {
            min-width: calc(100% - (var(--gutter) * 4));
        }

        @media (--tablet) {
            border-radius: 24px;
        }

        &--close {
            position: absolute;
            top: 1rem;
            right: 1rem;
            cursor: pointer;
            z-index: 10;
            padding: 1rem;
            font-size: 4rem;

            &:hover {
                opacity: .5;
            }
        }

        canvas {
            height: 100%;
        }
    }

    &__vimeo {
        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;

        iframe {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}
