.introduction {
    width: 100%;
    padding-top: 1.8rem;
    padding-bottom: 5rem;
    display: flex;
    flex-direction: column;
    min-height: var(--survey-min-height);

    @media (--tablet) {
        padding-bottom: 0;
        justify-content: center;
    }

    .introduction-inner {
        &__content {
            h3 {
                color: var(--black);
                margin-bottom: 3.4rem;
                width: 100%;
            }

            p {
                width: 100%;
                font-weight: var(--font-weight-regular);

                @media (min-width: 768px) {
                    font-weight: var(--font-weight-light);
                }

                @media (--tablet) {
                    font-size: var(--p-larger);
                    line-height: 3.4rem;
                }
            }

            &--video {
                padding-bottom: 90%;

                @media (--tablet) {
                    padding-bottom: 0;
                }
                h3 {
                    @media (min-width: 768px) {
                        width: 60%;
                    }
                }

                p {
                    width: 50%;
                    position: absolute;
                    right: 0;
                    top: 25rem;

                    @media (min-width: 768px) {
                        width: 60%;
                        position: static;
                    }
                }
            }
        }

        .video-wrapper {
            position: absolute;
            top: 20rem;
            left: 0;

            @media (min-width: 768px) {
                top: 50%;
                right: 0;
                left: auto;
                transform: translateY(-50%);
            }
        }
    }
}
