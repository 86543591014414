.podcast-detail {
    padding-bottom: 10rem;

    @media (--tablet) {
        max-width: var(--wide-page-width);
        margin: 0 auto;

        /* .page-wrapper {
            padding-left: 0;
            padding-right: 0;
        } */
    }

    hr {
        margin-top: 2.8rem;
        margin-bottom: 2.8rem;
    }

    &__tile {
        @media (--tablet) {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-flow: row nowrap;

            hr {
                display: none;
            }
        }

        .podcast-tile {
            display: flex;
            flex-direction: column-reverse;
            flex: 1 1 66%;

            &__title {
                display: none;
            }
        }
    }

    &__back-link {
        text-transform: uppercase;
        font-size: var(--subtitle);
        line-height: 1.6rem;
        letter-spacing: 0.1em;
        color: var(--orange);
        font-weight: var(--font-weight-regular);
    }

    &__image {
        @media (--tablet) {
            flex: 1 1 66%;
        }
    }
    &__summary {
        @media (--tablet) {
            padding-left: 3.2rem;
            flex: 1 1 33%;
        }
        p {
            font-style: normal;
            font-weight: var(--font-weight-regular);
            font-size: 2rem;
            line-height: 2.7rem;
            color: var(--dark-gray);
            margin-top: 1.2rem;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1.2rem 0;

        h2 {
            flex: 1;
        }

        svg {
            cursor: pointer;
        }

        @media (--tablet) {
            padding-bottom: 3.2rem;
            border-bottom: 4px solid var(--light-gray);
            margin-bottom: 4.8rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
                width: 17rem;
            }
        }
    }
}
