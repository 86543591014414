.result_quadrant {
    
    p {
        font-size: var(--p-larger);
        line-height: 2.7rem;

        @media (--desktop) {
            line-height: 3.2rem;
        }
    }

    p + .result_video {
        margin: 2.8rem 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .video-wrapper {
            flex: 0 0 calc((100% - var(--gutter)) / 2);
            width: calc((100% - var(--gutter)) / 2);
            height: 0;
            padding-top: calc((100% - var(--gutter)) / 2 * 1138 / 640);
            position: relative;
            border-radius: 1.2rem;
            overflow: hidden;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        h5 {
            width: calc((100% - var(--gutter)) / 2);
            color: var(--orange);
        }

        @media screen and (max-width: 500px) {
            display: none;
        }
    }

    .phone-view-video-container {

        h1 {
            font-size: 2.2rem;
            font-weight: 600;
            margin: 2.5rem 0 .8rem;
            line-height: normal;
        }
        p {
            margin: 0 0 30px 0;
        }
    }
    .result_video_phone_view {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media screen and (max-width: 500px) {
        .phone-view-video-container {
            display: block;
        }
    }
}
