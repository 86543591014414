.sr-survey-container {
  width: 100%;
  height: 100%;

  .submit-btn {
    display: block;
    margin: 0 auto;
  }

  @media (--tablet) {
    width: 50rem;
    height: auto;
  }
}