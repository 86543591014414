.assertive-results-page {
    h3 {
        color: var(--black);
        margin-bottom: 1.5rem;

        @media (--tablet) {
            margin-bottom: 3.2rem;
        }
    }

    p {
        margin-bottom: 1.2rem;
        font-size: var(--p-larger);
        line-height: 2.7rem;
        color: var(--dark-gray);

        @media (--tablet) {
            line-height: 3.2rem;
        }
    }

    p + p {
        margin-top: 4.8rem;
    }
}
.assertive-results-chart {
    margin-bottom: 4.8rem;

    hr {
        border: none;
        border-top: 0.2rem solid var(--light-gray);
        margin: 4rem auto;
    }

    &__heading {
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        h3 {
            margin-bottom: 0;
        }

        &__toggle {
            display: flex;
            align-items: center;

            svg {
                margin-right: 0.8rem;
            }

            p {
                color: var(--orange);
                text-decoration: underline;
                cursor: pointer;
                font-size: var(--p);
                margin: 0;
            }
        }
    }

    &__definitions {
        margin-top: 2.2rem;
        h4 {
            color: var(--petrol);
            margin-bottom: 1.2rem;
        }

        p {
            margin-bottom: 4.8rem;
            font-size: var(--p-larger);

            @media (--tablet) {
                line-height: 3.2rem;
            }
        }
    }

    &__item-name {
        color: var(--petrol);
        font-size: 1.3rem;
        line-height: 1.6rem;
        text-transform: uppercase;
        margin-top: 2.7rem;
        margin-bottom: 0.8rem;
        letter-spacing: 0.1em;
    }
    &__item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }
    &__item-bar-wrapper {
        flex: 1 1 100%;
        background-color: var(--light-gray);
        height: 2rem;
    }
    &__item-bar {
        background-color: var(--orange);
        height: 100%;
    }
    &__item-percent {
        flex: 0 0 5.6rem;
        font-size: 2rem;
        line-height: 2.7rem;
        color: var(--orange);
        text-align: right;
    }
}
