.radio-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: .5rem 0;
    font-size: 2rem;

    &__box {
        width: 3rem;
        height: 3rem;
        border: .2rem solid var(--orange);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: .5rem;

        &--selected {
            width: 70%;
            height: 70%;
            background-color: tomato;
            border-radius: 50%;
        }
    }
}