.pagination__controls {
    align-items: center;
    justify-content: space-between;

    &__arrows {
        button {
            background: none;
            border: none;
            margin: 0;
            padding: 0;
            cursor: pointer;
            transition: opacity 0.2s ease-in-out;

            &:disabled {
                opacity: 0.3;
                cursor: auto;

                &:hover {
                    opacity: 0.3;
                }
            }

            &:hover {
                opacity: 0.8;
                transition: opacity 0.2s ease-in-out;
            }

            &:first-of-type {
                transform: scaleX(-1);
                margin-right: 1.6rem;
            }
        }
    }

    &__skip {
        border-radius: 3.2rem;
        padding: 1.5rem 0;
        font-size: 2rem;
        background-color: var(--white);
        border: 2px solid var(--petrol);
        color: var(--petrol);
        padding: 1rem;
        width: 10rem;
        height: 4.8rem;
        text-align: center;
        cursor: pointer;
        margin-bottom: 0.5rem;

        @media (--tablet) {
            width: 13.8rem;
        }

        &:hover {
            opacity: 0.7;
        }
    }

    p {
        color: var(--mid-gray);
    }

    span {
        color: var(--orange);
        font-weight: var(--font-weight-regular);
        font-size: 2.5rem;
        line-height: 3.4rem;
        margin-right: 0.8rem;

        @media (--tablet) {
            font-size: 3.5rem;
            line-height: 4.2rem;
        }
    }
}
