.job-legend {
  margin: 0 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &__color {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 5px;
    border-radius: 50%;
    border-color: transparent;
    background-color: blue;
  }

  &__title {
    font-weight: 400;
    font-size: 1.5rem;
  }

  &:hover {
    opacity: .9;
  }
}