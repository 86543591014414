:root {
  --primary: #FFCE00;
  --secondary: #FE4880;
  --dark: #212121;
  --light: #F3F3F3;
}

.card {
  width: 560px;
  perspective: 1000px;
  display: flex;

  @media screen and (max-width: 450px) {
    width: 300px;
  }

  .card-inner {
    width: 100%;
    transition: transform 1s;
    transform-style: preserve-3d;
    cursor: pointer;
    position: relative;

    .card-face {
      position: absolute;
      width: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      overflow: hidden;
      border-radius: 16px;
      box-shadow: 0px 3px 18px 3px rgba(0, 0, 0, 0.2);
      padding: 20px;
      display: flex;
      align-items: center;
    }

    .face-front {
      background-image: linear-gradient(114deg, rgba(255, 158, 0, 1) 0%, rgba(254, 72, 128, 1) 100%);
      display: flex;
      align-items: center;
      justify-content: center;

      .face-front-children {
        color: white;
        text-align: center;

        h1 {
          line-height: inherit;
          font-size: 2.2rem;
          color: white;
        }

        span {
          font-size: 3rem;
          display: inline-flex;
          padding: 2px 10px;
          margin-top: 20px;
          border: 2px solid;
          border-radius: 50%;
          box-shadow: 0 0 20px -3px;
          transition: .3s;

          &:hover {
            box-shadow: 0 0 100px 50px;
          }
        }
      }
    }

    .face-back {
      background-color: var(--light);
      transform: rotateY(180deg);
    }
  }

  .isFlipped {
    transform: rotateY(180deg);
  }
}

.height100 {
  height: 100%;
}

.card-face-children-container {
  width: 100%;

  .flip-card-ul {
    padding: 2rem;
    list-style-type: disc;

    li {
      margin: 5px 0;
      color: var(--black);
      font-size: 1.6rem;
      line-height: 1.3;
    }
  }

  h2 {
    color: var(--black);
    line-height: 1;
    font-size: 2.2rem;
  }
}