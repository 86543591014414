.podcast-player {
    display: flex;
    flex-flow: row nowrap;

    &__image {
        width: 6rem;
        height: 6rem;
        display: block;
        background-color: var(--light-gray);
        border-radius: 3rem;
        overflow: hidden;
        object-fit: contain;
    }

    &__image-wrapper {
    }

    &__title-wrapper {
        flex: 1 1 50%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: flex-start;
        text-align: left;
    }

    &__playback-type {
        color: var(--orange);
        text-transform: uppercase;
        font-size: 1.3rem;
        line-height: 1.6rem;
        margin-bottom: 0.3rem;
    }

    &__marquee {
        height: 2.1rem; /* equal to text line height */
        width: 90%;

        overflow: hidden;
        position: relative;

        div {
            display: block;
            width: 200%;

            position: absolute;
            overflow: hidden;

            animation: marquee 10s linear infinite;

            &:hover {
                animation-play-state: paused;
            }
        }

        span {
            float: left;
            width: 50%;
            color: var(--petrol);
            font-size: 1.6rem;
            line-height: 2.1rem;
        }

        @keyframes marquee {
            0% {
                left: 0;
            }
            100% {
                left: -100%;
            }
        }

        &:before,
        &:after {
            content: "";
            width: 5rem;
            height: 100%;
            position: absolute;
            top: 0;
            background: var(--gradient-white-ltr);
            z-index: 1;

            @media (--tablet) {
                width: 10rem;
            }
        }

        &:before {
            left: 0;
            transform: rotate(-180deg);
        }

        &:after {
            right: 0;
        }
    }

    &__button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__mini-play-button,
    &__expand-button {
        margin-right: 2rem;
        cursor: pointer;
    }

    &__mini-close-button {
        /* top: 3.4rem;
        right: 3.4rem; */
        padding-left: 2rem;
        cursor: pointer;
        height: 5.6rem;
        border-left: 1px solid var(--mid-gray);
        display: flex;
        align-items: center;
        color: var(--dark-gray);
        &:hover {
            color: var(--red);
        }
        svg {
            width: 1.8rem;
            path {
                fill: currentColor;
            }
        }
    }
}
