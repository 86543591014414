.courses-progress-chart {
    @media (--tablet) {
        display: flex;
    }

    svg {
        width: 100%;
        max-width: 22rem;
        height: auto;
        margin: 0 auto;
        display: block;

        .chart-value {
            fill: white;
            font: 1.7px sans-serif;

            &.inverse {
                fill: var(--orange);
            }
        }

        @media (--tablet) {
            margin: 0;
            max-width: 36rem;
            padding-right: var(--gutter);
        }
    }
    ul {
        margin: 2.8rem auto;
        font-size: var(--h5);
        line-height: 1.8;
        color: var(--petrol);

        em {
            font-style: normal;
            color: var(--mid-gray);
            display: none;
        }

        @media (--tablet) {
            align-self: center;
            flex: 0 0 auto;
            margin-top: 4rem;
            margin-bottom: 4rem;
        }
    }
    .dot {
        display: inline-block;
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 3rem;
        margin-right: 2rem;
    }
}
