.page-wrapper {
    background: white;
    padding: calc(var(--gutter) * 2) var(--gutter);

    &--full-width {
        margin-left: calc(var(--gutter) * -1);
        margin-right: calc(var(--gutter) * -1);
    }

    &--full-height {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (--tablet) {
            height: auto;
        }
    }

    &--player-is-visible {
        padding-bottom: calc(var(--gutter) + 10rem);
    }
}
