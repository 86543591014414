:root {
    /* grid */
    --gutter: 20px;

    /* colors */

    /* primary */
    --orange: #f86459;
    --yellow: #ffb151;

    /* secondary */
    --petrol: #07586b;
    --red: #f53b3b;

    /* grayscale */
    --black: #22292b;
    --dark-gray: #676b6d;
    --mid-gray: #c2c4c4;
    --gray: #e1e1e2;
    --light-gray: #f0f0f0;
    --white: #ffffff;

    /* delivering */
    --dark-blue: #0c3c84;
    --light-blue: #a3ced2;

    /* influencing */
    --dark-red: #9b1e1e;
    --light-red: #ffa5a6;

    /* examining */
    --green: #1c9381;
    --light-green: #ffc378;

    /* connecting */
    --blue: #245390;
    --pink: #ffa5a6;

    --gradient-white: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 100%
    );

    /* left to right */
    --gradient-white-ltr: linear-gradient(
        270deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 89.22%
    );

    /* typography */

    --font-family: "GT Walsheim";
    --font-weight-light: 300;
    --font-weight-regular: 400;

    --base-font-size: 1.6rem;
    --scale: 1.25;

    --p: var(--base-font-size);
    --p-smaller: calc(var(--base-font-size) / (var(--scale)));
    --p-larger: calc(var(--base-font-size) * var(--scale));

    --subtitle: calc(var(--base-font-size) / (var(--scale)));

    --h5: var(--base-font-size);
    --h4: calc(var(--h5) * var(--scale));
    --h3: calc(var(--h4) * var(--scale));
    --h2: calc(var(--h3) * var(--scale));
    --h1: calc(var(--h2) * var(--scale));
    --h1-big: calc(var(--h1) * var(--scale));

    /* header */

    --header-height: 6.6rem;
    --header-margin: 0.8rem;
    --survey-header-height: 11.5rem;

    /* main container max-width */
    --page-width: calc(76.8rem - var(--gutter) * 2);
    --gradient-width: 10rem;
    --narrow-page-padding: 22rem;

    --narrow-page-width: calc(
        var(--page-width) - (var(--narrow-page-padding) * 2)
    );
    --wide-page-width: var(--page-width);
    --wide-page-width-minus-gutter: calc(
        var(--wide-page-width) - var(--gutter) * 2
    );

    --survey-min-height: calc(
        var(--vh, 1vh) * 100 - var(--survey-header-height) - 2.8rem - 10rem
    );

    /* breakpoints as in ./breakpoints.css file */
    @media (min-width: 800px) {
        --base-font-size: 1.8rem;
        --gutter: 30px;
        --narrow-page-width: 64rem;
    }

    @media (min-width: 1024px) {
        --page-width: calc(100vw - var(--narrow-page-padding) * 2);
    }

    @media (min-width: 1440px) {
        --page-width: 110rem;
        --wide-page-width: 104rem;
    }
}
