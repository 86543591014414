header {
    display: flex;
    justify-content: center;

    .header {
        width: calc(100% - (var(--header-margin) * 2));
        z-index: 1000;
        position: fixed;

        height: var(--header-height);
        padding: 2rem;
        border-radius: 33px;
        margin-top: var(--header-margin);
        overflow: hidden;
        clip-path: border-box;

        transition: border-radius 0.3s 0.4s ease-in-out,
            margin-top 0.3s 0.4s ease-in-out, width 0.3s 0.4s ease-in-out,
            height 0.3s ease-in-out;

        @media (--big-screen) {
            width: 120rem;
        }

        &.expanded {
            width: 100%;
            height: 100%;
            margin-top: 0;
            border-radius: 0;

            transition: border-radius 0.3s ease-in-out,
                margin-top 0.3s ease-in-out, width 0.3s ease-in-out,
                height 0.3s 0.4s ease-in-out;

            .header__heading {
                &__links {
                    display: none;
                }
                transition: height 0.3s 0.4s ease-in-out;

                @media (--tablet) {
                    height: 10rem;
                }
            }
        }

        .header__background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            svg {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__heading {
            position: absolute;
            width: 100%;
            height: calc(var(--header-height) + 0.2rem);
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            transition: height 0.3s ease-in-out;

            @media (--tablet) {
                border-bottom: 2px solid rgba(255, 255, 255, 0.2);
                left: 50%;
                transform: translateX(-50%);
            }

            @media (--desktop) {
                width: calc(100vw - 24rem);
            }

            @media (--big-screen) {
                width: 120rem;
            }

            h1 {
                line-height: 0;

                a {
                    padding: 4rem 4rem 1.5rem 2rem;

                    @media (--desktop) {
                        padding-left: 4rem;
                    }
                }
            }

            &__open-button {
                padding: 4rem;
                padding-right: 2rem;
                cursor: pointer;

                @media (--desktop) {
                    padding-right: 4rem;
                }
            }

            &__links {
                display: none;

                @media (--desktop) {
                    display: block;

                    position: absolute;
                    right: 15rem;
                }

                a {
                    font-size: var(--p-larger);
                    color: var(--white);
                }

                a:not(:last-child) {
                    margin-right: 5rem;
                }
            }
        }
    }
}

.fake-header {
    height: calc(var(--header-height) + var(--header-margin));
}

.bg-delivering {
    .shape-1 {
        fill: #0f3f85;
    }

    .shape-2 {
        fill: #1f4e8e;
    }

    .shape-3 {
        fill: #316097;
    }

    .shape-4 {
        fill: #416f9f;
    }

    .shape-5 {
        fill: #628fb0;
    }

    .shape-6 {
        fill: #75a2ba;
    }

    .shape-7 {
        fill: #8db9c7;
    }

    .shape-8 {
        fill: #a3ced2;
    }
}

.bg-influencing {
    .shape-1 {
        fill: #f53b3b;
    }

    .shape-2 {
        fill: #f74c4d;
    }

    .shape-3 {
        fill: #f85959;
    }

    .shape-4 {
        fill: #f96667;
    }

    .shape-5 {
        fill: #fa7071;
    }

    .shape-6 {
        fill: #fc8586;
    }

    .shape-7 {
        fill: #fe9596;
    }

    .shape-8 {
        fill: #ffa5a6;
    }
}

.bg-examining {
    .shape-1 {
        fill: #229481;
    }

    .shape-2 {
        fill: #389980;
    }

    .shape-3 {
        fill: #529e7f;
    }

    .shape-4 {
        fill: #6ca47e;
    }

    .shape-5 {
        fill: #96ad7c;
    }

    .shape-6 {
        fill: #b2b37b;
    }

    .shape-7 {
        fill: #dfbc79;
    }

    .shape-8 {
        fill: #fec378;
    }
}

.bg-connecting {
    .shape-1 {
        fill: #fea5a6;
    }

    .shape-2 {
        fill: #e29aa3;
    }

    .shape-3 {
        fill: #c690a0;
    }

    .shape-4 {
        fill: #ae879e;
    }

    .shape-5 {
        fill: #907b9b;
    }

    .shape-6 {
        fill: #727098;
    }

    .shape-7 {
        fill: #526495;
    }

    .shape-8 {
        fill: #295591;
    }
}
