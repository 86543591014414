.personalised-video {
  position: relative;
  height: 20rem;
  width: 100%;
  cursor: pointer;
  border-radius: 15px;
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: .5s;

  @media screen and (min-width: 600px) {
    height: 35rem;
  }

  &:hover {
    opacity: .7;
    transition: .5s;

    svg {
      transform: scale(1.5);
      transition: .5s;
    }
  }

  svg {
    transition: .5s;
    position: absolute;
    color: var(--orange);
    top: calc(50% - 3rem);
    left: calc(50% - 3rem);
    height: 6rem;
    width: 6rem;
  }
}

.connecting-preview {
  background-image: url('/src/assets/landscape-video-preview/Connecting-preview.png');
  background-size: cover;
}

.examining-preview {
  background-image: url('/src/assets/landscape-video-preview/Examining-preview.png');
}

.influencing-preview {
  background-image: url('/src/assets/landscape-video-preview/Influencing-preview.png');

}

.delivering-preview {
  background-image: url('/src/assets/landscape-video-preview/Delivering-preview.png');
}

.Assertiveness-connecting-preview {
  background-image: url('/src/assets/landscape-video-preview/Assertiveness-Connecting-preview.png');
  background-size: 100% 100%;
}

.Assertiveness-examining-preview {
  background-image: url('/src/assets/landscape-video-preview/Assertiveness-Examining-preview.png');
  background-size: 100% 100%;
}

.Assertiveness-influencing-preview {
  background-image: url('/src/assets/landscape-video-preview/Assertiveness-Influencing-preview.png');
  background-size: 100% 100%;
}

.Assertiveness-delivering-preview {
  background-image: url('/src/assets/landscape-video-preview/Assertiveness-Delivering-preview.png');
  background-size: 100% 100%;
}

.PWC-preview {
  background-image: url('/src/assets/landscape-video-preview/PWC-preview.png');
  background-size: 100% 100%;
}

.PWC2-preview {
  background-image: url('/src/assets/landscape-video-preview/PWC2-preview.png');
  background-size: 100% 100%;
}

.PYP-preview {
  background-image: url('/src/assets/landscape-video-preview/PYP.png');
  background-size: 100% 100%;
}

.HTSP-preview {
  background-image: url('/src/assets/landscape-video-preview/HTSP.png');
  background-size: 100% 100%;
}

.HTSP2-preview {
  background-image: url('/src/assets/landscape-video-preview/HTSP2.png');
  background-size: 100% 100%;
}

.HTDGS-preview {
  background-image: url('/src/assets/landscape-video-preview/HTDGS.png');
  background-size: 100% 100%;
}

.HTPWB-preview {
  background-image: url('/src/assets/landscape-video-preview/HTPWB.png');
  background-size: 100% 100%;
}

.HTHO1-preview {
  background-image: url('/src/assets/landscape-video-preview/HTHO1.png');
  background-size: 100% 100%;
}

.HTHO2-preview {
  background-image: url('/src/assets/landscape-video-preview/HTHO2.png');
  background-size: 100% 100%;
}