body {
    color: var(--black);
    font-family: var(--font-family);
    font-weight: var(--font-weight-regular);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: var(--font-weight-regular);
}

h1 {
    font-size: var(--h1);
    line-height: 4.7rem;

    &.big {
        font-size: var(--h1-big);
        line-height: 6.2rem;

        @media (--desktop) {
            line-height: 7.6rem;
        }
    }

    @media (--desktop) {
        line-height: 6rem;
    }
}

h2 {
    font-size: var(--h2);
    line-height: 3.7rem;

    @media (--desktop) {
        line-height: 4.8rem;
    }
}

h3 {
    font-size: var(--h3);
    line-height: 3.4rem;

    @media (--desktop) {
        line-height: 4.2rem;
    }
}

h4 {
    font-size: var(--h4);
    line-height: 2.7rem;

    @media (--desktop) {
        line-height: 3.4rem;
    }
}

h5 {
    font-size: var(--h5);
    line-height: 2.1rem;

    @media (--desktop) {
        line-height: 2.8rem;
    }
}

p {
    margin: 0;
    font-size: var(--p);
    line-height: 2.4rem;
    font-weight: var(--font-weight-light);

    &.smaller {
        font-size: var(--p-smaller);
        line-height: 1.8rem;
        font-weight: var(--font-weight-regular);

        @media (--desktop) {
            line-height: 2rem;
        }
    }

    &.larger {
        font-size: var(--p-larger);
        line-height: 2.7rem;

        @media (--desktop) {
            line-height: 3.2rem;
        }
    }

    &.subtitle {
        font-size: var(--subtitle);
        line-height: 1.6rem;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        color: var(--orange);
        font-weight: var(--font-weight-regular);

        @media (--tablet) {
            font-size: var(--p);
            line-height: 2.2rem;
        }
    }

    &.subtitle + h1 {
        margin-top: 1.2rem;
    }
}

p + p {
    margin-top: 1rem;
}

a {
    color: var(--orange);
    text-decoration: none;
}
