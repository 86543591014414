.podcasts {
    h1,
    h2,
    h3 {
        color: var(--petrol);
        max-width: var(--wide-page-width-minus-gutter);
        margin-left: auto;
        margin-right: auto;

        @media (--tablet) {
            color: var(--black);
        }
    }
    h1 {
        margin-bottom: 2rem;
        @media (--tablet) {
            font-size: var(--h1-big);
            line-height: 6rem;
        }
    }

    hr {
        max-width: var(--wide-page-width-minus-gutter);
    }
    /* hr:first-of-type {
        margin-top: 2rem;
        margin-bottom: 2.8rem;
    } */
    .section-with-badge {
        &:first-of-type {
            margin-top: 2.8rem;
        }

        &__title {
            font-size: 2.5rem;
            line-height: 3.4rem;

            @media (--tablet) {
                font-size: 3.5rem;
                line-height: 4.2rem;
            }
        }
    }
}
