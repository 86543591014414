.custom-modal {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: rgba(255, 255, 255, .7);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-text {
    background: var(--petrol);
    color: white;
    width: 80%;
    padding: 10rem 4rem;
    border-radius: 40px;
    position: relative;

    @media screen and (min-width: 600px) {
      width: 60%;
      padding: 12rem 6rem;
      font-size: 2rem;
    }
    @media screen and (min-width: 1500px) {
      width: 20%;
      padding: 12rem 6rem;
      font-size: 2rem;
    }

    button {
      position: absolute;
      top: 2rem;
      right: 3rem;
      font-size: 3rem;
      padding: 0;
      background: transparent;
      color: white;
      border: none;
    }
    
    h1 {
      font-size: 1.8rem;
      line-height: normal;
    }
  }
}