.tip {
    > h4 {
        margin: 2rem 0;

        @media (--tablet) {
            margin: 2.4rem 0;
        }
    }

    > p {
        font-size: var(--p);
        line-height: 2.4rem;
        font-weight: var(--font-weight-light);

        font-size: var(--p-larger);
        line-height: 2.7rem;

        @media (--desktop) {
            line-height: 3.2rem;
        }
    }

    &--cstyle {
        margin-top: 3rem;

        @media (--tablet) {
            margin-top: 7.3rem;
        }

        .subtitle {
            margin-bottom: 0.8rem;
            font-size: var(--p-smaller);
            line-height: 1.6rem;

            @media (--tablet) {
                font-size: var(--p);
                line-height: 2.2rem;
            }
        }

        h3 {
            text-transform: capitalize;
            color: var(--petrol);

            @media (--tablet) {
                font-size: 3.5rem;
                line-height: 4.2rem;
            }
        }

        p {
            font-size: var(--p);
            line-height: 2.7rem;

            @media (--tablet) {
                font-size: var(--p-larger);
                line-height: 3.2rem;
            }
        }

        &__banner-wrapper {
            height: 3.7rem;
            position: relative;
            overflow: hidden;
            border-radius: 13px;
            margin: 2.1rem 0;

            @media (--tablet) {
                height: 6.4rem;
                margin: 3.2rem 0;
            }
        }

        &__banner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            svg {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &--answer {
        font-weight: bold;
        color: black;
        margin: 2rem 0;

        @media (--tablet) {
            margin: 2.4rem 0;
        }
    }

    &--question {
        &.wrong {
            color: #f53b3b;
        }

        &::after {
            content: "";
            display: block;
            margin: 2.4rem 0;
            height: 0.2rem;
            background: #f0f0f0;
        }
    }

    &--label {
        color: var(--dark-gray);
        margin-bottom: 1.6rem;
    }

    &--content {
        p {
            margin: 0;
            font-weight: var(--font-weight-light);

            font-size: var(--p-larger);
            line-height: 2.7rem;

            @media (--desktop) {
                line-height: 3.2rem;
            }
        }

        h4 {
            margin-bottom: 2.2rem;
        }
    }
}

.pagination--tips .tip {
    margin-top: 2.4rem;

    &--label {
        color: var(--dark-gray);
        margin-bottom: 1.4rem;
    }
}
