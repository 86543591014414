.podcast-tile {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.clickable {
        cursor: pointer;
    }

    &__progress-bar {
        padding: 0;
        /* margin-top: 0; */
    }

    &__image {
        background-color: var(--light-gray);
        width: 100%;
        position: relative;
        padding-top: 66.53%;

        img {
            position: absolute;
            top: 0;
            left: 0;
        }

        .play-pause-button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0.8;
            z-index: 1;

            circle {
                fill: var(--white);
            }

            path {
                fill: var(--petrol);
            }
        }

        &--is-detail {
            @media (--tablet) {
                width: 65rem;
                height: 38rem;
            }
        }
    }

    &__badge {
        position: absolute;
        top: 0.8rem;
        left: 0.8rem;
        background-color: var(--white);
        font-size: 1.2rem;
        line-height: 1.2rem;
        padding: 0.8rem 1.2rem;
        text-transform: uppercase;
        color: var(--petrol);
        border-radius: 2rem;
    }

    &__title {
        color: var(--petrol);
        font-size: 2rem;
        line-height: 2.7rem;
        margin-top: 1.2rem;

        width: 30rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__duration-info {
        color: var(--orange);
        margin-top: 2rem;
        line-height: 2.7rem;
    }
}
