.message {
    h2 {
        margin-bottom: 2.8rem;
        color: var(--petrol);
    }

    p {
        text-align: center;
        color: var(--dark-gray);
        margin-bottom: 3.8rem;
    }

    &__icon {
        width: 10rem;
        height: 10rem;
        background-color: gray;
        margin-bottom: 1.4rem;
    }

    .page-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__cta {
        display: flex;
        width: 100%;
        margin-top: 4.4rem;
        justify-content: space-between;

        .button-primary {
            width: calc(100% - (var(--gutter) * 4));
            margin: 0 auto;

            @media (--tablet) {
                width: 17.4rem;
            }
        }

        &--multiple {
            .button-primary {
                flex: 1;
                margin-left: 1.5rem;
            }

            .button-secondary {
                flex: 1;
            }
        }
    }

    &__video-wrapper {
        height: 50rem;

        iframe {
            width: 100%;
            height: 100%;
        }
    }
}
