.assertiveness-results {
  .page-wrapper {
    padding-bottom: 0;
  }

  @media (--tablet) {
      max-width: var(--wide-page-width);
      margin: 0 auto;

      .action-panel {
          button {
              margin: 0 auto;
          }
      }
  }

  a {
    text-transform: uppercase;
    font-size: var(--subtitle);
    line-height: 1.6rem;
    letter-spacing: 0.1em;
    margin-bottom: 1.2rem;
  }
  .location-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .retake-btn {
      width: 50%;
      text-align: right;
      
      button {
        width: auto;
        padding-left: 1.2rem;
        padding-right: 1.2rem;

        @media screen and (min-width: 500px) {
          width: 90%;
        }
      }
  }
  .profile-span {
    color: black;
    font-weight: bold;
    font-size: var(--subtitle);

    &::before {
      content: '>';
      color: grey;
      padding: 0 5px;
    }
  }


  .text-section {
    margin: 4rem 0 2.5rem;
    @media screen and (min-width: 800px) {
      margin: 7rem 0 2.5rem;
    }
    
    h3 {
      font-size: 1.7rem;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 2rem;

      @media screen and (min-width: 800px) {
        margin: 4rem 0;
        font-size: var(--p-larger);
      }
    }
    p {
      margin-bottom: 2rem;
      font-size: 1.6rem;
      @media screen and (min-width: 800px) {
        font-size: var(--p-larger);
      }

      strong {
        font-weight: normal;
        color: var(--orange);
      }
    }
  }
  .chart-section {
    margin-top: 4rem;

    h1 {
      font-size: 2rem;
      font-weight: 600;
    }
  }

  .bottom-text-section {
    margin-top: 4rem;
    @media screen and (min-width: 800px) {
      margin-top: 7rem;
    }
    h3 {
      font-size: 1.7rem;
      font-weight: 600;
      line-height: normal;

      @media screen and (min-width: 800px) {
        font-size: var(--p-larger);
      }
    }
    
    p {
      margin-top: 2rem;
      font-size: 1.6rem;
      @media screen and (min-width: 800px) {
        font-size: var(--p-larger);
      }

      strong {
        font-weight: normal;
        color: var(--orange);
      }
    }
  }

  .personalised-video-container {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
  }

  .recommended-videos-container {
    margin: 4rem 0;
    display: block;

    h1 {
      font-size: 2rem;
      font-weight: 600;

    }

    .video-container {
      display: flex;
      align-items: center;
      margin: 2rem 0;
      padding-left: 4%;
      @media screen and (min-width: 700px) {
        display: block;
        padding-left: 0;
      }
      

      .round-gradient {
        cursor: pointer;
        transition: 1s;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &:hover {
          transform: scale(1.1);
          transition: .5s;
          opacity: .5;
        }
        @media screen and (min-width: 700px) {
          margin: 1rem auto;
          width: 150px;
          height: 150px;
        }
  
        .cstyle-picture {
          width: 90%;
          height: 90%;
          background-size: cover;
          border-radius: 50%;
        }

        svg {
          position: absolute;
          top: calc(50% - 15px);
          left: calc(50% - 15px);
          color: var(--orange);
          width: 30px; 
          height: 30px;
        }
      }
      .video-labels-container {
        margin-left: 3rem;
        @media screen and (min-width: 700px) {
          margin-left: 0;
          padding-top: 1.5rem;
          text-align: center;
        }
        h1 {
          font-size: 1.5rem;
          line-height: normal;
        }
      }
    }
    @media screen and (min-width: 700px) {
      .videos-container {
        display: flex;
        justify-content: space-evenly;
      }
    }
  }
  
  .orange-banner-container {
    width: 100vw;
    transform: translateX(calc(-1 * var(--gutter)));
    @media screen and (min-width: 700px) {
      width: 100%;
      transform: translateX(0);
      border-radius: 7px;
    }

    h3 {
      color: white;
      margin: 2rem 0;
    }
  }

  .bottom-btns-container {
    width: 100vw;
    transform: translateX(calc(-1 * var(--gutter)));

    @media screen and (min-width: 800px) {
      width: 100%;
      transform: translateX(0);
    }
  }

  .labels-video-player {
    display: none;
  }
  .labels-video-playing {
      top: 0;
      left: 0;
      position: fixed;
      z-index: 1000;
      width: 100vw;
      height: 100vh;
      background: rgba(255,255,255,.8);
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .video-container {
      position: relative;

      .video-close {
          position: absolute;
          right: 1rem;
          top: 1rem;
          cursor: pointer;
      }
  }
}