.progress-bar-container {
  margin-bottom: 3rem;
  h2 {
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: normal;
  }

  p {
    width: 80%;
    @media screen and (min-width: 500px) {
      width: 90%;
    }
  }

  .bar-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .grey-bar {
      position: relative;
      border-radius: 40px;
      height: 1.8rem;
      width: 80%;
      background: var(--light-gray);
      @media screen and (min-width: 500px) {
        width: 90%;
      }

      .orange-bar {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 40px;
        background: var(--orange);
        transition: 3s;
        display: flex;
        justify-content: center;
        align-items: center;

        p {    
          font-size: 1.6rem;
          position: absolute;
          right: -4rem;
          font-weight: 400;
        }
      }
    }

    p {
      color: var(--orange);
      width: auto;
      cursor: pointer;
    }
  }
}
.center {
  display: flex;
  align-items: center;
  margin-top: 0;
}
.hide {
  display: none;
}


