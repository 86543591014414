.pagination {
    padding-top: 1.8rem;
    padding-bottom: 8rem;
    min-height: calc(var(--survey-min-height) - 10rem);

    @media (--tablet) {
        padding-top: 0;
        padding-bottom: 0;
    }

    &--video {
        display: flex;

        align-items: center;
        flex-direction: column-reverse;
        justify-content: flex-end;
        width: 100%;

        min-height: calc(var(--survey-min-height));

        @media (--tablet) {
            flex-direction: row;
            justify-content: space-between;
        }

        &__content {
            width: 100%;
            margin-bottom: 5rem;

            @media (--tablet) {
                flex: 1;
                margin-left: 3rem;
            }

            &__copy {
                min-height: 10rem;
                display: flex;
                flex-direction: column;
                padding-bottom: 1.6rem;
                border-bottom: 2px solid var(--light-gray);

                @media (--tablet) {
                    min-height: 12rem;
                    justify-content: flex-end;
                    padding-bottom: 4.8rem;
                    margin-bottom: 0;
                }

                h3 {
                    color: var(--black);
                }

                /* here used with the same style as subtitles */
                h4 {
                    font-size: var(--subtitle);
                    line-height: 1.6rem;
                    text-transform: uppercase;
                    letter-spacing: 0.1em;
                    color: var(--orange);
                    font-weight: var(--font-weight-regular);
                    margin-bottom: 0.8rem;

                    @media (--tablet) {
                        font-size: var(--p);
                        line-height: 2.2rem;
                    }
                }
            }
        }

        &__video-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            width: 100%;
            height: 100%;

            @media (--tablet) {
                margin-top: 0;
                display: block;
                width: auto;
                height: auto;
            }

            .pagination__controls--mobile-controls {
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                transform: translateY(-50%);

                .pagination__controls__arrows {
                    display: flex;
                    justify-content: space-between;
                }

                p,
                span {
                    display: none;
                }

                @media (--tablet) {
                    display: none;
                }
            }

            .video-wrapper {
                @media (--tablet) {
                    position: relative;
                    transform: none;
                }
                z-index: 1;
            }
        }
    }

    &--tips {
        display: flex;
        flex-direction: column;
        height: 100%;

        @media (--tablet) {
            margin-top: 5rem;
        }

        &__heading {
            border-bottom: 2px solid var(--light-gray);
            margin-bottom: 1.6rem;
            padding-bottom: 1.6rem;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            min-height: calc(27px * 2 + 16px * 2 + 2px);

            @media (--tablet) {
                margin-bottom: 2.4rem;
                padding-bottom: 2.2rem;
                min-height: calc(34px * 2 + 40px * 2 + 2px);
            }

            .subtitle {
                margin-bottom: 1.6rem;
                color: var(--dark-gray);

                @media (--tablet) {
                    margin-bottom: 2.2rem;
                }
            }

            h4 {
                color: var(--black);
                @media (--tablet) {
                    font-size: 2.8rem;
                }
            }

            &--wrong {
                h4 {
                    color: var(--red);
                }
            }
        }

        &__no-tips {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: calc(var(--survey-min-height) - 10rem);

            h3 {
                color: var(--petrol);

                margin-bottom: 2rem;

                @media (--tablet) {
                    margin-bottom: 3.8rem;
                }
            }

            p {
                font-size: var(--p-larger);
                line-height: 2.7rem;
                color: var(--dark-gray);
                font-weight: var(--font-weight-regular);

                @media (--tablet) {
                    line-height: 3.4rem;
                    font-weight: var(--font-weight-light);
                }
            }

            &__background {
                width: 100%;
                height: 100%;
                position: fixed;
                top: 0;
                left: 0;
                background-image: url("../../assets/images/rippl-shape.svg");
                background-repeat: no-repeat;
                background-position: center;
                z-index: -1;
            }
        }
    }

    &__controls {
        &--video {
            display: none;

            @media (--tablet) {
                display: flex;
                width: auto;
                transform: none;
                position: static;
                margin-top: 2.4rem;
            }

            .pagination__controls__arrows {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media (--tablet) {
                    width: auto;
                    display: block;
                }
            }

            p,
            span {
                display: none;

                @media (--tablet) {
                    display: inline-block;
                }
            }
        }

        &--tips {
            display: flex;
        }
    }
}
