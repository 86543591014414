.certifications {
    @media (--tablet) {
        max-width: var(--narrow-page-width);
        margin: 0 auto;
    }

    a {
        text-transform: uppercase;
        font-size: var(--subtitle);
        line-height: 1.6rem;
        letter-spacing: 0.1em;
        color: var(--orange);
        font-weight: var(--font-weight-regular);
    }

    p {
        &.larger {
            color: var(--dark-gray);
        }
    }

    .section-with-badge {
        margin-top: 1.2rem;
        margin-bottom: 4.4rem;
    }
    .section-with-badge__badge {
        position: absolute;
        right: 0;
        top: 0.2em;
    }
}
