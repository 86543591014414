.search-select-container {
  width: 100%;
  height: 100%;
  position: relative;

  .select-fields {
    position: absolute;
    width: 100%;
    margin: 0 auto;
    top: 50px;
    left: 0;
    box-shadow: 0 2px 5px -2px black;
    border-radius: 4px;
    z-index: 5;
    background-color: white;

    h1 {
      font-size: 2rem;
      padding: 1rem;
      line-height: normal;
    }
  }
}

.h1-hover {
  background-color: lightblue;
  cursor: pointer;
}

.d-none {
  display: none;
}