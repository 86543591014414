.dashboard {
    margin: 0 auto;
    padding-bottom: 1rem;

    @media (--desktop) {
        width: calc(100% - 44rem);
    }

    @media (--big-screen) {
        max-width: var(--wide-page-width);
    }

    .page-wrapper {
        padding-bottom: 0;
    }
    .subtitle {
        margin-bottom: 1.2rem;
    }

    .level-banner-section {
        min-height: 0px;
        margin: 4rem auto 0;
        h1 {
            font-size: 2.2rem;
            margin-bottom: 1.5rem;
            font-weight: 600;
        }

        h3 {
            color: black;
            font-size: 1.8rem;
            margin-top: 1rem;
            text-align: center;
        }

        .user-level-container {
            height: 12rem;
            position: relative;
            overflow: hidden;
            width: 100vw;
            transform: translateX(calc(-1 * var(--gutter)));
            padding: 20px 0;
            min-height: 0px;
            
            @media screen and (min-width: 600px) {
                .bg-container {
                    svg {
                        height: 1700px;
                    }
                }
            }
            @media screen and (min-width: 1000px) {
                width: 100%;
                transform: translateX(0);
                .bg-container {
                    svg {
                        height: 1700px;
                    }
                }
            }
            @media screen and (min-width: 1025px) and (max-width: 1170px) {
                width: 100%;
                transform: translateX(0);
                .bg-container {
                    svg {
                        height: 1000px;
                    }
                }
            }
            
            .bg-container {
                position: absolute;
                top: 0;
                left: 0;
                z-index: -2;
                height: 12rem;
                width: 100%;
                overflow: hidden;

                svg {
                    width: 100%;
                }
            }
            .text-container {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 12rem;
                z-index: 1;
                h2 {
                    color: white;
                    margin: 0;
                    width: 60%;
                    text-align: center;
                }
                span {
                    color: white;
                    margin: 0;
                    width: 20%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 2.3rem;
                    cursor: pointer;
                }
            }
            
        }

    }
    .tests-section {
        margin: 4rem auto 0;
        h1 {
            font-size: 2.2rem;
            margin-bottom: 1.5rem;
            font-weight: 600;
        }

        .tests-container {
            display: flex;
            justify-content: center;

            .cStyle-container {
                width: 49%;
                text-align: center;
                
                h1 {
                    line-height: normal;
                    height: 4rem;
                    font-size: 1.5rem;
                    margin: 0;
                }
                hr {
                    width: 25%;
                    margin: 1.5rem auto;
                }
                svg {
                    height: 75px;
                    width: 75px;
                    transform: translateX(5px);
                }
                img {
                    width: 30px;
                    height: 30px;
                }
                @media screen and (min-width: 1100px) {
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: space-around;
                    align-items: center;
                    
                    hr {
                        display: none;
                    }
                }
            }
            .assertiveness-container {
                width: 50%;
                text-align: center;
                border-left: 2px solid rgba(0,0,0, .5);

                h1 {
                    line-height: normal;
                    height: 4rem;
                    font-size: 1.5rem;
                    margin: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                hr {
                    width: 25%;
                    margin: 1.5rem auto;
                }
                svg {
                    height: 75px;
                    width: 75px;
                    transform: translateX(5px);
                }
                @media screen and (min-width: 1100px) {
                    height: 120px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    
                    hr {
                        display: none;
                    }
                    h1{
                        display: flex;
                        align-items: center;
                        margin: 0;
                    }
                }
            }
        }
    }

    .courses-section {
        margin: 4rem auto 0;
        h1 {
            font-size: 2.2rem;
            margin-bottom: 1.5rem;
            font-weight: 600;
        }
    }

    .bottom-buttons-section {
        margin: 8rem auto 0;
        
        .button-primary {
            display: block;
            margin-bottom: 1rem;
        }

        @media screen and (min-width: 800px) {
            display: flex;
            justify-content: space-around;
            padding-bottom: 2rem;

            .button-primary {
                margin-bottom: 0;
                width: 45%;
                transition: 1s;
            }
            .button-secondary {
                width: 45%;
                transition: 1s;
            }
        }
    }

    .action-panel {
        .button-primary {
            display: block;
        }
        @media (--tablet) {
            display: none;
        }
    }

    &__section {
        @media (--tablet) {
            flex: 1 1 50%;
        }
    }

    &__row {
        display: flex;
        flex-flow: column nowrap;

        @media (--tablet) {
            flex-flow: row nowrap;
        }
    }
    .button-primary {
        display: none;
        &--desktop {
            @media (--tablet) {
                display: block;
                margin: 4.8rem 0;
            }
        }
    }

    .courses-progress-chart {
        flex-flow: column nowrap;
        ul {
            margin: 2.8rem 0;
            align-self: flex-start;
        }
    }
}

.small-btn {
    border-radius: 40px;
    padding: 8px 20px;
    color: white;
    cursor: pointer;
    border: none;
    background-color: var(--petrol);

    &:hover {
        opacity: .6;
    }
}

.hide {
    display: none !important;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none; 
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; 
    user-select: none; 
  }