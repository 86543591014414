.pannel-container {
  width: 100%;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 0 0 10px -4px;
  align-items: center;
  justify-content: center;
  color: rgb(110, 108, 108);

  &__jobs {
    display: flex;
    margin-bottom: 2rem;
  }

  .pannel-controls-container {
    display: flex;
    margin-left: 20px;

    &>* {
      margin: 0 10px;
    }
  }
}