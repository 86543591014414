.chart-with-legend {
  width: 50%;
  text-align: center;

  label {
    font-size: 2rem;
  }

  .chart-group {
    .chart-container {
      width: 50%;
      margin: 2rem auto;
    }

    .legends-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}