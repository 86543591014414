.level-indicator {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;

    svg {
        width: 100%;
        height: auto;
    }

    &__badge-wrapper {
        position: relative;
        z-index: 2;
        flex: 0 0 6rem;
        width: 6rem;
        height: 6rem;
        margin: -0.1em;
        transition: background-color 0.7s;

        @media (--tablet) {
            height: 12rem;
            width: 12rem;
            flex: 0 0 12rem;
        }
    }
    &__info-section {
        padding-left: 2rem;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-self: flex-end;

        @media (--tablet) {
            padding-left: 4rem;
            align-self: center;
        }
    }

    &__label {
        font-size: 1.3rem;
        line-height: 1.6rem;
        text-transform: uppercase;
        color: var(--orange);
        @media (--tablet) {
            font-size: var(--h5);
            line-height: 1.4;
        }
    }

    &__name {
        font-size: var(--h3);
        line-height: 3.4rem;
        margin-top: 0.4rem;
        @media (--tablet) {
            font-size: var(--h2);
            line-height: 1.4;
        }
    }
}
