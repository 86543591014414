.expandable-div-container {
    .expandable-div-header {
        padding-bottom: 1rem;

        .view-more-span {
            font-size: 1.5rem;
            display: inline-flex;
            align-items: center;
            margin-left: .2rem;
            cursor: pointer;
            color: rgb(41, 142, 242);
            
            .label-button {
                width: 8rem;
                text-align: right;
            }
            svg {
                margin-left: 5px;
            }
        }
    }
    .expandable-div-content {
        display: none;
    }
}

.rotate-up {
    transition: .5s;
}
.rotate-down {
    transform: rotate(-180deg);
    transition: .5s;
}
