body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

hr {
    border: none;
    border-top: 0.2rem solid var(--light-gray);
    margin: 4rem auto;
    max-width: var(--page-width);

    @media (--tablet) {
        border-top-width: 0.4rem;
    }
}
