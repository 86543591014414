.global-map-container {
  @media screen and (min-width: 800px) {
    max-width: var(--wide-page-width);
    margin: 0 auto;
  }

  .gmap-section {
    margin: 5rem 0;

    h1 {
      font-size: 3rem;
      font-weight: 400;
      border-bottom: 2px solid rgba(0, 0, 0, .1);
      margin-bottom: 2rem;
    }
  }
}

.pulse-btn {
  display: block;
  margin: 0 auto;
  border-radius: 50px;
  padding: 15px 50px;
  background-color: #f5ab0e;
  color: white;
  border: none;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 600;
  font-family: 'GT Walsheim';
}