@font-face {
    font-family: "GT Walsheim";
    src: url("../assets/fonts/GT-Walsheim-Light.woff2") format("woff2"),
        url("../assets/fonts/GT-Walsheim-Light.woff") format("woff");
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: "GT Walsheim";
    src: url("../assets/fonts/GT-Walsheim-Regular.woff2") format("woff2"),
        url("../assets/fonts/GT-Walsheim-Regular.woff") format("woff");
    font-style: normal;
    font-weight: 400;
}
