.certificates-tile {
    border-bottom: 2px solid var(--light-gray);
    display: flex;
    cursor: pointer;
    padding-bottom: 2rem;
    margin-bottom: 2rem;

    @media (--tablet) {
        border-bottom: none;
    }

    &__image {
        width: 4.3rem;
        height: 5.1rem;
        margin-right: 2rem;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__item {
        flex: 1;
        display: flex;
        flex-direction: row;
        width: 100%;

        &__title {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            h4 {
                color: var(--petrol);
                margin-bottom: 1.2rem;
            }

            h5 {
                color: var(--mid-gray);
            }
        }
    }

    &:last-of-type {
        border: none;
    }
}
